import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TAmplifyUser,
  TSignUpAmplifyDataUI2,
  TAmplifyErrorPayload,
  EErrorCode,
  TForgotPasswordUser,
  EVia,
} from 'data/types/Amplify.types';
import { AuthAmplify } from '../apiSlice/authAmplify.slice';
import { AuthAmplifyWithEmail } from '../apiSlice/authAmplifyWithEmail.slice';
import { AuthAmplifyWithPhoneNumber } from '../apiSlice/authAmplifyWithPhoneNumber.slice';

export type TAuthSliceState = {
  amplifyUser: TAmplifyUser | null;
  signInUser: TSignUpAmplifyDataUI2 | null;
  forgotPasswordUser: TForgotPasswordUser | null;
};

const initialState: TAuthSliceState = {
  // user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null,
  amplifyUser: localStorage.getItem('amplifyUser')
    ? JSON.parse(localStorage.getItem('amplifyUser') as string)
    : null,
  signInUser: null,
  forgotPasswordUser: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setForgotPasswordVerificationCode(
      state,
      action: PayloadAction<{ verificationCode: string }>,
    ) {
      if (!state.forgotPasswordUser) {
        return;
      }
      state.forgotPasswordUser.verificationCode =
        action.payload.verificationCode;
    },
    setAmplifyUser(state, action: PayloadAction<TAmplifyUser>) {
      state.amplifyUser = action.payload;
      localStorage.setItem('amplifyUser', JSON.stringify(action.payload));
    },
    removeSignInUser(state) {
      state.signInUser = null;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        AuthAmplify.endpoints.currentAuthUserAmplify.matchFulfilled,
        (state, { payload }) => {
          state.amplifyUser = payload;
          localStorage.setItem('amplifyUser', JSON.stringify(payload));
        },
      )
      .addMatcher(
        AuthAmplify.endpoints.currentAuthUserAmplify.matchRejected,
        state => {
          state.amplifyUser = null;
          localStorage.removeItem('amplifyUser');
        },
      )
      .addMatcher(
        AuthAmplifyWithEmail.endpoints.signUpWithEmailAmplify.matchFulfilled,
        (state, { payload }) => {
          state.signInUser = { ...payload, via: EVia.EMAIL };
        },
      )
      .addMatcher(
        AuthAmplifyWithPhoneNumber.endpoints.signUpWithPhoneNumberAmplify
          .matchFulfilled,
        (state, { payload }) => {
          state.signInUser = { ...payload, via: EVia.PHONE };
        },
      )
      .addMatcher(
        AuthAmplifyWithEmail.endpoints.forgotPasswordWithEmailAmplify
          .matchFulfilled,
        (state, { payload }) => {
          state.forgotPasswordUser = { username: payload.username };
        },
      )
      .addMatcher(
        AuthAmplifyWithEmail.endpoints.forgotPasswordWithEmailAmplify
          .matchRejected,
        (state, { payload }) => {
          const { data } = payload as TAmplifyErrorPayload;
          if (data.code === EErrorCode.INVALID_PARAMETER_EXCEPTION) {
            state.signInUser = {
              username: data.username,
              password: '',
              userConfirmed: false,
              codeSend: false,
              via: EVia.EMAIL,
            };
          }
        },
      )
      .addMatcher(
        AuthAmplifyWithPhoneNumber.endpoints
          .forgotPasswordWithPhoneNumberAmplify.matchFulfilled,
        (state, { payload }) => {
          state.forgotPasswordUser = { username: payload.username };
        },
      )
      .addMatcher(
        AuthAmplifyWithPhoneNumber.endpoints
          .forgotPasswordWithPhoneNumberAmplify.matchRejected,
        (state, { payload }) => {
          const { data } = payload as TAmplifyErrorPayload;
          if (data.code === EErrorCode.INVALID_PARAMETER_EXCEPTION) {
            state.signInUser = {
              username: `+${data.username}`,
              password: '',
              userConfirmed: false,
              codeSend: false,
              via: EVia.PHONE,
            };
          }
        },
      )
      .addMatcher(
        AuthAmplifyWithEmail.endpoints.signInWithEmailAmplify.matchFulfilled,
        (state, { payload }) => {
          state.amplifyUser = payload;
          localStorage.setItem('amplifyUser', JSON.stringify(payload));
        },
      )
      .addMatcher(
        AuthAmplifyWithEmail.endpoints.signInWithEmailAmplify.matchRejected,
        (state, { payload }) => {
          const { data } = payload as TAmplifyErrorPayload;
          if (data.code === EErrorCode.USER_NOT_CONFIRMED_EXCEPTION) {
            state.signInUser = {
              username: data.username,
              password: data.password,
              userConfirmed: false,
              codeSend: false,
              via: EVia.EMAIL,
            };
          }
        },
      )
      .addMatcher(
        AuthAmplifyWithPhoneNumber.endpoints.signInWithPhoneNumberAmplify
          .matchFulfilled,
        (state, { payload }) => {
          state.amplifyUser = payload;
          localStorage.setItem('amplifyUser', JSON.stringify(payload));
        },
      )
      .addMatcher(
        AuthAmplifyWithPhoneNumber.endpoints.signInWithPhoneNumberAmplify
          .matchRejected,
        (state, { payload }) => {
          const { data } = payload as TAmplifyErrorPayload;
          if (data.code === EErrorCode.USER_NOT_CONFIRMED_EXCEPTION) {
            state.signInUser = {
              username: data.username,
              password: data.password,
              userConfirmed: false,
              codeSend: false,
              via: EVia.PHONE,
            };
          }
        },
      )
      .addMatcher(
        AuthAmplify.endpoints.signOutAmplify.matchFulfilled,
        state => {
          state.amplifyUser = null;
          localStorage.clear();
        },
      );
  },
});

export default authSlice;
