import {
  configureStore,
  combineReducers,
  CombinedState,
  AnyAction,
} from '@reduxjs/toolkit';
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import authSlice from './slices/auth.slice';
import appSlice from './slices/app.slice';
import testSlice from './slices/test.slice';
import { userApi } from './apiSlice/user.slices';
import { SomeApi } from './apiSlice/some.slices';
import { AuthAmplify } from './apiSlice/authAmplify.slice';
import { AuthAmplifyWithPhoneNumber } from './apiSlice/authAmplifyWithPhoneNumber.slice';
import { AuthAmplifyWithEmail } from './apiSlice/authAmplifyWithEmail.slice';
import { applicationApi } from './apiSlice/application.slice';
import { visitationApi } from './apiSlice/visitation.slices';
import { animalApi } from './apiSlice/animal.slices';
import { policyApi } from './apiSlice/policy.slice';
import { tenantApi } from './apiSlice/tenant.slices';
import { PropertyManagementApi } from './apiSlice/propertyManagement.slices';
import filtersSlice from './slices/filters.slice';
import { fileApi } from './apiSlice/file.slice';
import { storageApi } from './apiSlice/storage.slice';
import { DocPacketApi } from './apiSlice/docPacket.slice';
// template-flag-0

type TState =
  | CombinedState<{
      [x: string]: unknown;
    }>
  | undefined;

const appReducer = combineReducers({
  [SomeApi.reducerPath]: SomeApi.reducer,
  testSlice: testSlice.reducer,
  authSlice: authSlice.reducer,
  appSlice: appSlice.reducer,
  [storageApi.reducerPath]: storageApi.reducer,
  filtersSlice: filtersSlice.reducer,
  [AuthAmplify.reducerPath]: AuthAmplify.reducer,
  [AuthAmplifyWithPhoneNumber.reducerPath]: AuthAmplifyWithPhoneNumber.reducer,
  [AuthAmplifyWithEmail.reducerPath]: AuthAmplifyWithEmail.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [visitationApi.reducerPath]: visitationApi.reducer,
  [animalApi.reducerPath]: animalApi.reducer,
  [policyApi.reducerPath]: policyApi.reducer,
  [tenantApi.reducerPath]: tenantApi.reducer,
  [PropertyManagementApi.reducerPath]: PropertyManagementApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [DocPacketApi.reducerPath]: DocPacketApi.reducer,
  // template-flag-1
});

const rootReducer = (state: TState, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  // @ts-ignore
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      AuthAmplify.middleware,
      AuthAmplifyWithPhoneNumber.middleware,
      AuthAmplifyWithEmail.middleware,
      fileApi.middleware,
      applicationApi.middleware,
      visitationApi.middleware,
      animalApi.middleware,
      policyApi.middleware,
      tenantApi.middleware,
      PropertyManagementApi.middleware,
      DocPacketApi.middleware,
      storageApi.middleware,
      // template-flag-2
    ),
});

export const clearAllCachedData = () => {
  store.dispatch(AuthAmplify.util.resetApiState());
  store.dispatch(AuthAmplifyWithPhoneNumber.util.resetApiState());
  store.dispatch(AuthAmplifyWithEmail.util.resetApiState());
  store.dispatch(applicationApi.util.resetApiState());
  store.dispatch(visitationApi.util.resetApiState());
  store.dispatch(animalApi.util.resetApiState());
  store.dispatch(policyApi.util.resetApiState());
  store.dispatch(tenantApi.util.resetApiState());
  store.dispatch(DocPacketApi.util.resetApiState());
  store.dispatch(PropertyManagementApi.util.resetApiState());
  store.dispatch(storageApi.util.resetApiState());
  // template-flag-3
};

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = cb =>
  useSelector(cb, shallowEqual);

setupListeners(store.dispatch);
