import React, { Suspense, FC, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { get } from 'lodash';
import authSlice from 'redux/slices/auth.slice';
import { useCurrentAuthenticatedUserAmplifyQuery } from 'redux/apiSlice/authAmplify.slice';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

const RoutesPage: FC = () => {
  const [loading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const amplifyUser = useAppSelector(state => state.authSlice.amplifyUser);
  const { isLoading } = useCurrentAuthenticatedUserAmplifyQuery(loading, {
    skip: loading,
    refetchOnMountOrArgChange: true,
  });
  const dispatch = useAppDispatch();
  const devOrigins = [import.meta.env.REACT_APP_OPP_WEB_HOSTNAME_LOCAL];
  const prodOrigins = [import.meta.env.REACT_APP_OPP_WEB_HOSTNAME];
  const origins =
    import.meta.env.MODE === 'development' ? devOrigins : prodOrigins;
  const onMessageListening = (event: MessageEvent) => {
    if (!origins.includes(event.origin)) {
      return;
    }
    localStorage.clear();
    const { to, ...admin } = JSON.parse(event.data);
    if (
      get(admin, ['attributes', 'custom:userType']) !== 'admin landlord tenant'
    ) {
      return;
    }
    const origin =
      import.meta.env.MODE === 'development'
        ? import.meta.env.REACT_APP_OPP_WEB_HOSTNAME_LOCAL
        : import.meta.env.REACT_APP_OPP_WEB_HOSTNAME;
    window.opener.postMessage('received', origin);
    localStorage.setItem('user', JSON.stringify(admin));
    Object.keys(admin.storage).forEach(key => {
      localStorage.setItem(key, admin.storage[key]);
    });

    dispatch(authSlice.actions.setAmplifyUser(admin.attributes));
    setIsLoading(false);
    navigate(`/app${to}`);
  };

  useEffect(() => {
    window.addEventListener('message', onMessageListening);
    return () => {
      window.removeEventListener('message', onMessageListening);
    };
  }, []);

  return (
    <Suspense fallback={isLoading || loading}>
      <Routes>
        <Route
          path="/*"
          element={<PublicRoutes isLoggedIn={!!amplifyUser} />}
        />
        <Route
          path="app/*"
          element={<PrivateRoutes isLoggedIn={!!amplifyUser} />}
        />
      </Routes>
    </Suspense>
  );
};
export default RoutesPage;
