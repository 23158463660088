import {defaultDateFormat, formattedDate} from 'helpers/utils';
import omitBy from 'lodash/omitBy';
import moment from 'moment';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import {omit} from 'lodash';
import {TAuthConverter, TConvertor, TFetchConverter,} from 'data/types/Converter.types';
import {
  EApplicationStepName,
  EApplicationStepNameUi,
  EApplicationStepType,
  EApplicationStepTypeUi,
  EApplicationTypeDB,
  EApplicationTypeUI,
  EStatusChangerRoleDB,
  EStatusChangerRoleUI,
  TApplicationAnimal,
  TApplicationAnimalUi,
  TApplicationDb,
  TApplicationDetails,
  TApplicationProppertyManagmentDB,
  TApplicationProppertyManagmentUI,
  TApplicationStep,
  TApplicationStepUi,
  TApplicationUI,
  TApplicationVerificationStatisticsDB,
  TApplicationVerificationStatisticsUI,
  TTApplicationDetailsUi,
} from 'data/types/Applications.types';

import {
  animalTypesConvertor,
  applicationAnimalVetRecordConvertor,
  applicationSADocPacketConvertor,
  readableDetailsGenderConverter,
  readableWeightFieldConvertor,
} from 'data/convertors/Animal.converters';
import {
  applicationStatusConvertor,
  failsConvertor,
  readableFieldConvertor,
  readableYesNoOptionFieldConvertor,
  statusChangeInfoConverter,
  verificationStatusConverter,
} from './General.converters';

export const applicationStatusChangerRoleConvertor: TConvertor<
  EStatusChangerRoleUI,
  EStatusChangerRoleDB
> = {
  fromDb: data => {
    return EStatusChangerRoleUI[
      EStatusChangerRoleDB[data] as keyof typeof EStatusChangerRoleUI
    ];
  },
  toDb: data => {
    switch (data) {
      case EStatusChangerRoleUI.ADMIN:
        return EStatusChangerRoleDB.ADMIN;
      default:
        return EStatusChangerRoleDB.PROPERTY_MANAGER;
    }
  },
};

export const applicationTypeConvertor: TConvertor<
  EApplicationTypeUI,
  EApplicationTypeDB
> = {
  fromDb: data => {
    return EApplicationTypeUI[
      EApplicationTypeDB[data] as keyof typeof EApplicationTypeUI
    ];
  },
  toDb: data => {
    return EApplicationTypeDB[
      EApplicationTypeUI[data] as keyof typeof EApplicationTypeDB
    ];
  },
};

export const applicationStepNameConvertor: TConvertor<
  EApplicationStepNameUi,
  EApplicationStepName
> = {
  fromDb: data => {
    return EApplicationStepNameUi[
      EApplicationStepName[data] as keyof typeof EApplicationStepNameUi
    ];
  },
  toDb: data => {
    return EApplicationStepName[
      EApplicationStepNameUi[data] as keyof typeof EApplicationStepName
    ];
  },
};

export const applicationStepTypeConvertor: TConvertor<
  EApplicationStepTypeUi,
  EApplicationStepType
> = {
  fromDb: data => {
    return EApplicationStepTypeUi[
      EApplicationStepType[data] as keyof typeof EApplicationStepTypeUi
    ];
  },
  toDb: data => {
    return EApplicationStepType[
      EApplicationStepTypeUi[data] as keyof typeof EApplicationStepType
    ];
  },
};

export const getApplicationsListConverter: TAuthConverter<
  { [key: string]: string | string[] } | undefined,
  { [key: string]: string | string[] },
  TApplicationUI[],
  TApplicationDb[]
> = {
  fromDb: data =>
    data.map(item => ({
      id: item.id,
      leaseStartDate: moment(item.leaseStartDate).format(defaultDateFormat),
      submittedDate: moment(item.submittedDate).format(defaultDateFormat),
      status: applicationStatusConvertor.fromDb(item.applicationStatus),
      statusChangerName: item.statusChangerName,
      statusChangerOrgName: item.statusChangerOrgName,
      type: EApplicationTypeUI[EApplicationTypeDB[item.type]],
      statusChangeInfo: item.statusChangeInfo
        ? statusChangeInfoConverter.fromDb(item.statusChangeInfo)
        : null,
      rejectReason: item.rejectReason,
      tenantName: item.tenantName,
      tenantPhoto: item.tenantPhoto,
      animalName: item.animalName,
      animalPhoto: item.animalPhoto,
      animalSpecie: item.animalSpecie,
      propertyManagementName: item.propertyManagementName,
      propertyManagementLogo: item.propertyManagementLogo,
      animalVerificationStatus: verificationStatusConverter.fromDb(
        item.animalVerificationStatus,
      ),
    })),
  toDb: filter => {
    if (!filter) {
      return {};
    }
    const validFilters = pick(filter, [
      'tenantName',
      'type',
      'animalNameSearch',
      'propertyManagementName',
      // 'leaseStartDate',
      // 'submittedDate',
      'status',
      'animalVerificationStatus',
    ]);

    return omitBy(
      {
        statuses: validFilters.status,
        animalVerificationStatus: validFilters.animalVerificationStatus,
        types: validFilters.type,
        animalNameSearch: validFilters.animalNameSearch,
        tenantIds: validFilters.tenantName,
        propertyManagementIds: validFilters.propertyManagementName,
      },
      val => isEmpty(val) || !val,
    );
  },
};

export const getApplicationSteps: TFetchConverter<
  TApplicationStepUi,
  TApplicationStep
> = {
  fromDb: data => ({
    stepImage: data.stepImage || undefined,
    subTitle: data.subtitle,
    stepName: applicationStepNameConvertor.fromDb(data.stepName),
    title: data.title,
    withWarnings: data.withWarnings,
    stepType: applicationStepTypeConvertor.fromDb(data.stepType),
  }),
};

export const getApplicationConverter: TFetchConverter<
  TTApplicationDetailsUi,
  TApplicationDetails
> = {
  fromDb: data => ({
    id: data.id,
    status: applicationStatusConvertor.fromDb(data.status),
    type: applicationTypeConvertor.fromDb(data.type),
    statusChangeInfo: data.statusChangeInfo
      ? statusChangeInfoConverter.fromDb(data.statusChangeInfo)
      : null,
    applicationSteps: data.applicationSteps.map(item =>
      getApplicationSteps.fromDb(item),
    ),
    rejectReason: data.rejectReason,
    animalVerificationStatus: verificationStatusConverter.fromDb(
      data.animalVerificationStatus,
    ),
  }),
};

export const getApplicationProppertyManagmentConverter: TFetchConverter<
  TApplicationProppertyManagmentUI,
  TApplicationProppertyManagmentDB
> = {
  fromDb: data => ({
    propertyManagementName: data.propertyManagementName,
    propertyManagementLogo: data.propertyManagementLogo,
    propertyManagerName: data.propertyManagerName,
    signature: data.signature
      ? {
          name: data.signature.name,
          date: moment(data.signature.date).format(defaultDateFormat),
        }
      : null,
    property: data.property,
    unit: data.unit,
    leaseStartDate: moment(data.leaseStartDate).format(defaultDateFormat),
    leaseToDate: data.leaseToDate
      ? moment(data.leaseToDate).format(defaultDateFormat)
      : null,
  }),
};

export const getApplicationVerificationStatisticsConverter: TFetchConverter<
  TApplicationVerificationStatisticsUI,
  TApplicationVerificationStatisticsDB
> = {
  fromDb: data => ({
    saDocumentPacketCount: data.saDocumentPacketCount,
    vetRecordCount: data.vetRecordCount,
    hpDocumentPacketCount: data.hpDocumentPacketCount,
    animalVerified: data.animalVerified,
    docsAreVerified: data.docsAreVerified,
  }),
};

export const applicationAnimalConvertor: TFetchConverter<
  TApplicationAnimalUi,
  TApplicationAnimal
> = {
  fromDb: data => {
    const documentsFails = pick(data.fails || {}, [
      'saDocumentPackets',
      'vetRecords',
    ]);

    return {
      id: data.id,
      name: readableFieldConvertor.fromDb(data.name),
      profilePicture: readableFieldConvertor.fromDb(data.profilePicture),
      species: readableFieldConvertor.fromDb(data.species),
      breed: readableFieldConvertor.fromDb(data.breed),
      primaryColor: readableFieldConvertor.fromDb(data.primaryColor),
      secondaryColor1: readableFieldConvertor.fromDb(data.secondaryColor1),
      secondaryColor2: readableFieldConvertor.fromDb(data.secondaryColor2),
      birthDate: readableFieldConvertor.fromDb(
        data.birthDate ? formattedDate(data.birthDate) : '',
      ),
      weight: readableWeightFieldConvertor.fromDb(data.weight),
      isHouseTrained: readableYesNoOptionFieldConvertor.fromDb(
        data.isHouseTrained,
      ),
      gender: readableDetailsGenderConverter.fromDb(data.gender),
      isReproductive: readableYesNoOptionFieldConvertor.fromDb(
        data.isReproductive,
      ),
      type: animalTypesConvertor.fromDb(data.type),
      status: verificationStatusConverter.fromDb(data.status),
      isDeleted: data.isDeleted,
      vetRecordList:
        data.vetRecords?.map(item =>
          applicationAnimalVetRecordConvertor.fromDb(item),
        ) || [],
      packets: (data.saDocumentPackets || []).map(item =>
        applicationSADocPacketConvertor.fromDb(item),
      ),
      fails: failsConvertor.fromDb(
        omit(data.fails || {}, ['saDocumentPackets', 'vetRecords']),
      ),
      documentsFails: failsConvertor.fromDb(documentsFails),
    };
  },
};
