import {
  getTenantSearchListConvertor,
  markTenantAsKnownConverter,
  tenantConverter,
  tenantDetailsConverter,
} from 'data/convertors/Tenant.convertors';
import {
  TFilter,
  TPageableDataWithContentUi,
  TTableFilters,
} from 'data/types/Table.types';
import {
  TMarkTenantAsKnownDB,
  TMarkTenantAsKnownUI,
  TTenantDB,
  TTenantDetailsDB,
  TTenantDetailsUI,
  TTenantSearchListDB,
  TTenantSearchListUI,
  TTenantUI,
} from 'data/types/Tenant.types';
import { ETenantUrls } from 'data/urls/tenant.url';
import { authSplitApi } from '../helpers/slice.helpers';
import { TMarkAsPNCUI } from '../../data/types/Animal.types';

export const tenantApi = authSplitApi('tenant', [
  'tenantSearchList',
  'tenantsList',
  'tenantDetails',
]).injectEndpoints({
  endpoints: build => ({
    getTenantSearchList: build.query<TTenantSearchListUI[], TFilter | void>({
      query(params) {
        return {
          url: `${ETenantUrls.TENANT}/${ETenantUrls.SEARCH}`,
          method: 'GET',
          params: {
            size: 2000,
            ...(params?.search && { search: params.search }),
          },
        };
      },
      providesTags: ['tenantSearchList'],
      transformResponse: (
        data: TPageableDataWithContentUi<TTenantSearchListDB[]>,
      ) => {
        const { content } = data;
        return getTenantSearchListConvertor.fromDb(content);
      },
    }),
    verifyHPDocPacket: build.mutation<
      void,
      { tenantId: string; hpDocumentPacketId: string }
    >({
      query({ tenantId, hpDocumentPacketId }) {
        return {
          url: `${ETenantUrls.TENANT}/${ETenantUrls.HP_DOC_PAKET}/${ETenantUrls.VERIFY}`,
          method: 'POST',
          body: { tenantId, hpDocumentPacketId },
        };
      },
    }),
    undoVerificationHPDocPacket: build.mutation<
      void,
      { tenantId: string; hpDocumentPacketId: string }
    >({
      query({ tenantId, hpDocumentPacketId }) {
        return {
          url: `${ETenantUrls.TENANT}/${ETenantUrls.HP_DOC_PAKET}/${ETenantUrls.VERIFY}`,
          method: 'DELETE',
          body: { tenantId, hpDocumentPacketId },
        };
      },
    }),
    turnNotificationOn: build.mutation<void, { tenantId: string }>({
      query(data) {
        return {
          url: `${ETenantUrls.TENANT}/${ETenantUrls.NOTIFICATIONS}/${ETenantUrls.ENABLE}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['tenantsList', 'tenantDetails'],
    }),
    turnNotificationOff: build.mutation<void, { tenantId: string }>({
      query(data) {
        return {
          url: `${ETenantUrls.TENANT}/${ETenantUrls.NOTIFICATIONS}/${ETenantUrls.DISABLE}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['tenantsList', 'tenantDetails'],
    }),
    getTenantsList: build.query<
      TPageableDataWithContentUi<TTenantUI[]>,
      TTableFilters<{ [key: string]: string | string[] }, string | undefined>
    >({
      query({ page, pageSize, filters }) {
        const validFilters = tenantConverter.toDb(filters);
        return {
          url: `${ETenantUrls.TENANT}?page=${page}&size=${pageSize}`,
          method: 'GET',
          params: {
            ...validFilters,
          },
        };
      },
      providesTags: ['tenantsList'],
      transformResponse: (data: TPageableDataWithContentUi<TTenantDB[]>) => {
        return {
          ...data,
          content: data.content.map(item => tenantConverter.fromDb(item)),
        };
      },
    }),
    getTenant: build.query<TTenantDetailsUI, string>({
      query(tenantId: string) {
        return {
          url: `${ETenantUrls.TENANT}/${ETenantUrls.DETAILS}`,
          method: 'GET',
          params: {
            tenantId,
          },
        };
      },
      providesTags: ['tenantDetails'],
      transformResponse: (data: TTenantDetailsDB) =>
        tenantDetailsConverter.fromDb(data),
    }),
    markAsKnown: build.mutation<TMarkTenantAsKnownDB, TMarkTenantAsKnownUI>({
      query(body: TMarkAsPNCUI) {
        return {
          url: `${ETenantUrls.TENANT}/${ETenantUrls.TAGS}/${ETenantUrls.KNOWN}`,
          method: 'POST',
          body: markTenantAsKnownConverter.toDb(body),
        };
      },
      invalidatesTags: ['tenantDetails'],
    }),
    removeAsKnown: build.mutation<TMarkTenantAsKnownDB, TMarkTenantAsKnownUI>({
      query(body: TMarkAsPNCUI) {
        return {
          url: `${ETenantUrls.TENANT}/${ETenantUrls.TAGS}/${ETenantUrls.KNOWN}`,
          method: 'DELETE',
          body: markTenantAsKnownConverter.toDb(body),
        };
      },
      invalidatesTags: ['tenantDetails'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTenantSearchListQuery,
  useTurnNotificationOnMutation,
  useTurnNotificationOffMutation,
  useVerifyHPDocPacketMutation,
  useUndoVerificationHPDocPacketMutation,
  useGetTenantsListQuery,
  useGetTenantQuery,
  useMarkAsKnownMutation,
  useRemoveAsKnownMutation,
} = tenantApi;
