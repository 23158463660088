import {
  TAuthConverter,
  TConvertor,
  TFetchConverter,
} from 'data/types/Converter.types';
import {
  EVisitationStatusDB,
  EVisitationStatusUI,
  TVisitationDB,
  TVisitationUI,
  TVisitationDetailDB,
  TVisitationDetailUI,
  EAuthorizationStatusUI,
  EAuthorizationStatusDB,
} from 'data/types/Visitations.types';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import moment from 'moment';
import { defaultDateFormat } from 'helpers/utils';
import {
  getFirstLetters,
  readableFieldConvertor,
  yesNoOptionConverter,
} from './General.converters';
import { propertyManagementConvertor } from './PropertyManagement.convertors';

export const visitationStatusConvertor: TConvertor<
  EVisitationStatusUI,
  EVisitationStatusDB
> = {
  fromDb: data => {
    return EVisitationStatusUI[
      EVisitationStatusDB[data] as keyof typeof EVisitationStatusDB
    ];
  },
  toDb: data => {
    switch (data) {
      case EVisitationStatusUI.APPROVED:
        return EVisitationStatusDB.APPROVED;
      case EVisitationStatusUI.REJECTED:
        return EVisitationStatusDB.REJECTED;
      case EVisitationStatusUI.ARCHIVED:
        return EVisitationStatusDB.ARCHIVED;
      default:
        return EVisitationStatusDB.PENDING;
    }
  },
};

export const authorizationStatusConvertor: TFetchConverter<
  EAuthorizationStatusUI,
  EAuthorizationStatusDB
> = {
  fromDb: data => {
    const status: { [key in EAuthorizationStatusDB]: EAuthorizationStatusUI } =
      {
        [EAuthorizationStatusDB.AUTHORIZED]: EAuthorizationStatusUI.AUTHORIZED,
        [EAuthorizationStatusDB.UN_AUTHORIZED]:
          EAuthorizationStatusUI.UN_AUTHORIZED,
      };
    return status[data];
  },
};

export const getVisitationListConverter: TAuthConverter<
  { [key: string]: string | string[] } | undefined,
  { [key: string]: string | string[] },
  TVisitationUI[],
  TVisitationDB[]
> = {
  fromDb: data =>
    data.map(item => ({
      id: item.id,
      tenantName: item.tenantName,
      firstLetters: getFirstLetters(item.tenantName),
      propertyName: item.propertyName,
      propertyManagementName: item.propertyManagementName,
      unitNumber: item.unitNumber,
      startDate: moment(`${item.startDate}z`).format(defaultDateFormat),
      endDate: moment(`${item.endDate}z`).format(defaultDateFormat),
      submittedDate: moment(`${item.submittedDate}z`).format(defaultDateFormat),
      status: visitationStatusConvertor.fromDb(item.status),
      rejectReason: item.rejectReason,
      tenantPhoto: item.tenantProfilePicture,
    })),
  toDb: filter => {
    if (!filter) {
      return {};
    }

    const validFilters = pick(filter, [
      'tenantName',
      'propertyManagementName',
      'status',
    ]);

    return omitBy(
      {
        statuses: validFilters.status,
        tenantIds: validFilters.tenantName,
        propertyManagementIds: validFilters.propertyManagementName,
      },
      val => isEmpty(val) || !val,
    );
  },
};

export const getVisitationsConvertor: TFetchConverter<
  TVisitationDetailUI,
  TVisitationDetailDB
> = {
  fromDb: data => ({
    id: data.id,
    animalProfilePicture: readableFieldConvertor.fromDb(
      data.animalProfilePicture || '',
    ),
    animalName: readableFieldConvertor.fromDb(data.animalName),
    species: readableFieldConvertor.fromDb(data.species.value || ''),
    breed: readableFieldConvertor.fromDb(data.breed?.value || ''),
    primaryColor: readableFieldConvertor.fromDb(data.primaryColor?.value || ''),
    secondaryColor1: readableFieldConvertor.fromDb(
      data.secondaryColor1?.value || '',
    ),
    secondaryColor2: readableFieldConvertor.fromDb(
      data.secondaryColor2?.value || '',
    ),
    startDate: `${data.startDate}Z`,
    endDate: `${data.endDate}Z`,
    explanation: readableFieldConvertor.fromDb(data.explanation),
    willBeLeftWithoutSupervision: yesNoOptionConverter.fromDb(
      data.willBeLeftWithoutSupervision,
    ),
    precautions: readableFieldConvertor.fromDb(data.precautions),
    didUserConfirmLearningEfforts: data.didUserConfirmLearningEfforts,
    status: visitationStatusConvertor.fromDb(data.status),
    isServiceAnimal: data.isServiceAnimal,
    trainedFor: readableFieldConvertor.fromDb(data.trainedFor || ''),
    requiredBecauseOfDisability: yesNoOptionConverter.fromDb(
      data.requiredBecauseOfDisability,
    ),
    authorization: authorizationStatusConvertor.fromDb(data.authorization),
    propertyManagement: propertyManagementConvertor.fromDb(
      data.propertyManagement,
    ),
    rejectReason: data.rejectReason,
    canEdit: data.modifiable,
  }),
};
