/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appLocalStorage, EStorageNames } from 'helpers/storage';

type TFiltersState = {
  filters: {
    [key in EStorageNames]: {
      [filter in any]?: string | string[];
    };
  };
  pagination: {
    [key in EStorageNames]: {
      page?: number;
      pageSize?: number;
    };
  };
};
const initialPaginationState = { page: 1, pageSize: 10 };
const initialState: TFiltersState = {
  filters: {
    [EStorageNames.APPLICATIONS]:
      appLocalStorage.getItem(EStorageNames.APPLICATIONS)?.filters || {},
    [EStorageNames.VISITATIONS]:
      appLocalStorage.getItem(EStorageNames.VISITATIONS)?.filters || {},
    [EStorageNames.ANIMALS]:
      appLocalStorage.getItem(EStorageNames.ANIMALS)?.filters || {},
    [EStorageNames.TENANTS]:
      appLocalStorage.getItem(EStorageNames.TENANTS)?.filters || {},
  },
  pagination: {
    [EStorageNames.APPLICATIONS]:
      appLocalStorage.getItem(EStorageNames.APPLICATIONS)?.pagination ||
      initialPaginationState,
    [EStorageNames.VISITATIONS]:
      appLocalStorage.getItem(EStorageNames.VISITATIONS)?.pagination ||
      initialPaginationState,
    [EStorageNames.ANIMALS]:
      appLocalStorage.getItem(EStorageNames.ANIMALS)?.pagination ||
      initialPaginationState,
    [EStorageNames.TENANTS]:
      appLocalStorage.getItem(EStorageNames.TENANTS)?.pagination ||
      initialPaginationState,
  },
};

export const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    setStoredFilters(
      state,
      action: PayloadAction<{
        keyStorageName: EStorageNames;
        filter: string;
        ids: string | string[];
      }>,
    ) {
      const newState: TFiltersState = {
        ...state,
        pagination: {
          ...state.pagination,
          [action.payload.keyStorageName]: {
            ...state.pagination[action.payload.keyStorageName],
            page: 0,
          },
        },
        filters: {
          ...state.filters,
          [action.payload.keyStorageName]: {
            ...state.filters[action.payload.keyStorageName],
            [action.payload.filter]: action.payload.ids,
          },
        },
      };
      appLocalStorage.setItem(action.payload.keyStorageName, {
        pagination: {
          ...state.pagination[action.payload.keyStorageName],
          page: 0,
        },
        filters: newState.filters[action.payload.keyStorageName],
      });
      return newState;
    },
    removeAllFilters(state) {
      const newState = state;
      Object.keys(state.filters).forEach(keyStorageName => {
        newState.filters[keyStorageName as EStorageNames] = {};
        appLocalStorage.removeItem(keyStorageName as EStorageNames);
      });
      return newState;
    },
    setPagination(
      state,
      action: PayloadAction<{
        keyStorageName: EStorageNames;
        page?: number;
        pageSize?: number;
      }>,
    ) {
      const newState: TFiltersState = {
        ...state,
        pagination: {
          ...state.pagination,
          [action.payload.keyStorageName]: {
            ...state.pagination[action.payload.keyStorageName],
            ...(action.payload.page && { page: action.payload.page }),
            ...(action.payload.pageSize && {
              pageSize: action.payload.pageSize,
            }),
          },
        },
      };
      appLocalStorage.setItem(action.payload.keyStorageName, {
        filters: state.filters[action.payload.keyStorageName],
        pagination: newState.pagination[action.payload.keyStorageName],
      });
      return newState;
    },
  },
});

export const { setStoredFilters, setPagination, removeAllFilters } =
  filtersSlice.actions;
export default filtersSlice;
