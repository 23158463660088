export const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];
export const oldHud =
  'https://www.hud.gov/sites/dfiles/PA/documents/HUDAsstAnimalNC1-28-2020.pdf?fbclid=IwAR3NcG1FMmTGaw7LZ__oMwWku7wWu4R51n6AbCyHw9tgUQgi95KiMc41C4Y';

export const hudGuidelinesLink =
  'https://www.hud.gov/sites/dfiles/PA/documents/HUDAsstAnimalNC1-28-2020.pdf';

export const replaceText = `If you have any questions or feel we’ve reached this conclusion in error, please contact us at support@ourpetpolicy.com or (208) 906-8886. Please refer to this document for ${hudGuidelinesLink} Guidelines.`;
export const oldReplaceText = `Please refer to this ${oldHud} for HUD Guidelines.`;

export enum EConversionReasonDB {
  SEVERAL_ATTEMPTS_TO_VERIFY = 'SEVERAL_ATTEMPTS_TO_VERIFY',
  NOT_RECEIVED_DOCUMENTATION = 'NOT_RECEIVED_DOCUMENTATION',
  NOT_REQUIRED_TO_ALLEVIATE = 'NOT_REQUIRED_TO_ALLEVIATE',
  WAS_NOT_PROVIDED_BY_ITSELF = 'WAS_NOT_PROVIDED_BY_ITSELF',
  NOT_REQUIRED_FOR_DISABILITY = 'NOT_REQUIRED_FOR_DISABILITY',
}
export enum EConversionReasonUI {
  SEVERAL_ATTEMPTS_TO_VERIFY = 'We have made several attempts to verify a disability-related need for an assistance animal with your healthcare provider, but regrettably, we have been unable to do so. Despite our requests for your assistance in facilitating communication with your provider, we have not received the necessary cooperation. Consequently, we are unable to proceed with the approval process at this time and your animal will have to be considered a pet. You may submit further documentation or have your provider contact us directly at any point during your lease, at which time we can resume the verification process.',
  NOT_RECEIVED_DOCUMENTATION = 'At this time, we have not received any documentation from a healthcare provider in support of your reasonable accommodation request. According to the HUD guidelines, a housing provider may request documentation from a licensed healthcare provider that verifies an individual’s non-observable disability and disability-related needs for an assistance animal as part of the process for approving a reasonable accommodation. Consequently, we are unable to proceed with the approval process at this time and your animal will have to be considered a pet. You may submit documentation at any point during your lease, at which time we can begin the verification process.',
  NOT_REQUIRED_TO_ALLEVIATE = 'It has been confirmed that this animal is not required to alleviate symptoms of a disability, therefore, the criteria for a reasonable accommodation has not been met. Consequently, this animal will have to be considered a pet. You may submit further documentation or clarification at any point during your lease, at which time we can resume the verification process.',
  WAS_NOT_PROVIDED_BY_ITSELF = 'In accordance with HUD guidelines, the documentation provided was not, by itself, sufficient to reliably establish that an individual has a non-observable disability and a disability-related need for an assistance animal. Consequently, we are unable to grant a reasonable accommodation at this time and your animal will have to be considered a pet. You may submit further documentation or clarification at any point during your lease, at which time we can resume the verification process.',
  NOT_REQUIRED_FOR_DISABILITY = 'The animal has been converted to a pet since you have indicated that the animal is not required for a disability. If you feel that this is an error, please contact us right away or submit a conversion request to begin the Assistance Animal verification process.',
}
