export enum EApplicationUrls {
  APPLICATION = 'application',
  STEP = 'step',
  PROPERTY_MANAGEMENT = 'property-management',
  ANIMAL = 'animal',
  TENANT = 'tenant',
  APPROVE = 'approve',
  REJECT = 'reject',
  VERIFICATION = 'verification',
  STATISTICS = 'statistics',
}
