import { Icon as BaseIcon, TIconNames } from 'components/Base/Icon';
import { Colors } from 'core/CssVariables';
import { RoutesPaths } from 'routes/Routes.types';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { TMenuListItem, TResult } from './Layout.types';

const menuList: TMenuListItem[] = [
  {
    label: 'Animals',
    key: `${RoutesPaths.ANIMALS}`,
    renderIcon: active => (
      <BaseIcon
        icon={TIconNames.PETS_HOME}
        color={active ? Colors.White : Colors.PrimaryColor}
        size={20}
        className="mr-2"
      />
    ),
  },
  {
    label: 'Visitations',
    key: `${RoutesPaths.VISITATIONS}`,
    renderIcon: active => (
      <BaseIcon
        icon={TIconNames.PET_POW_QUOTE}
        color={active ? Colors.White : Colors.PrimaryColor}
        size={20}
        className="mr-2"
      />
    ),
  },
  {
    label: 'Applications',
    key: `${RoutesPaths.APPLICATIONS}`,
    renderIcon: active => (
      <BaseIcon
        icon={TIconNames.PETS_POW}
        color={active ? Colors.White : Colors.PrimaryColor}
        size={20}
        className="mr-2"
      />
    ),
  },
  {
    label: 'Tenants',
    key: `${RoutesPaths.TENANTS}`,
    renderIcon: active => (
      <BaseIcon
        icon={TIconNames.PEOPLE}
        color={active ? Colors.White : Colors.PrimaryColor}
        size={20}
        className="mr-2"
      />
    ),
  },
  // todo delete when create first nested items
  //  nested path example
  // {
  //   label: 'valod',
  //   key: 'valod',
  //   renderIcon: active => (
  //     <BaseIcon
  //       icon={TIconNames.PETS_POW}
  //       color={active ? Colors.White : Colors.Black}
  //       size={20}
  //       className="mr-2"
  //     />
  //   ),
  //   children: [
  //     {
  //       label: 'valodi tgxa',
  //       key: 'valodi-tgxa',
  //       renderIcon: active => (
  //         <BaseIcon
  //           icon={TIconNames.PETS_POW}
  //           color={active ? Colors.White : Colors.Black}
  //           size={20}
  //           className="mr-2"
  //         />
  //       ),
  //       children: [
  //         {
  //           label: 'valodi tgxu tgxa',
  //           key: 'valodi-tgxu-tgxa',
  //           renderIcon: active => (
  //             <BaseIcon
  //               icon={TIconNames.PETS_POW}
  //               color={active ? Colors.White : Colors.Black}
  //               size={20}
  //               className="mr-2"
  //             />
  //           ),
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const useGetMenuItems = (): TResult => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getMenuItems = (
    menuItems: TMenuListItem[],
    parrentKey: string,
  ): TResult => {
    return menuItems.reduce<TResult>(
      (acc, item) => {
        const { label, key, renderIcon, children } = item;
        const itemKey = [parrentKey, key].filter(Boolean).join('/');
        const { menuItems: menueItem, activeKeys: activeKey } = getMenuItems(
          children || [],
          itemKey,
        );

        if (pathname.includes(`/app/${itemKey}`)) {
          acc.activeKeys.push(`/app/${itemKey}`);
        }
        acc.menuItems.push({
          key: `/app/${itemKey}`,
          label: (
            <>
              {renderIcon(pathname.includes(`/app/${itemKey}`))} {label}
            </>
          ),
          onClick: () => {
            if (!isEmpty(menueItem)) {
              return;
            }

            navigate(itemKey);
          },
          children: isEmpty(menueItem) ? null : menueItem,
        });

        acc.activeKeys = [...acc.activeKeys, ...activeKey];

        return acc;
      },
      {
        menuItems: [],
        activeKeys: [],
      },
    );
  };

  return getMenuItems(menuList, '');
};
