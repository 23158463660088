import {
  TExpirationDateType,
  TFail,
  TFailUI,
  TFileDataType,
  TFileDataTypeUI,
} from './GeneralTypes';

export enum EVetRecordStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}
export enum EVetRecordStatusUi {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}

export type TAnimalVetRecordUi = {
  documentFiles: TFileDataTypeUI[];
  id: string;
  records: TAnimalVetRecordTypeUI[];
  status: EVetRecordStatusUi;
};

export type TAnimalVetRecord = {
  documentFiles: TFileDataType[];
  id: string;
  records: TAnimalVetRecordTypeDB[];
  status: EVetRecordStatus;
};

export type TAnimalVetRecordTypeDB = {
  expiration: TExpirationDateType;
  id: string;
  isExpired: false;
  type: TAnimalVetRecordVacTypeDB;
};
export type TAnimalVetRecordTypeUI = {
  expiration: TExpirationDateType;
  id: string;
  isExpired: false;
  type: TAnimalVetRecordVacTypeUI;
};

export type TAnimalVetRecordVacTypeDB = {
  predefined: boolean;
  value: string;
};
export type TAnimalVetRecordVacTypeUI = {
  predefined: boolean;
  value: string;
};

export type TApplicationVetRecordsUI = TAnimalVetRecordUi & {
  fails?: TFailUI[];
};
export type TApplicationVetRecords = TAnimalVetRecord & {
  fails?: TFail;
};
