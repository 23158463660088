import { TFile } from './Storage.types';
import { TFileDataType, TFileDataTypeUI } from './GeneralTypes';

export enum EHPVisitationUI {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON',
}
export enum EHPVisitation {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON',
}

export enum EHPDocPacketTypesUI {
  SA = 'SA',
  ESA = 'ESA',
}
export enum EHPDocPacketTypes {
  SA = 'SA',
  ESA = 'ESA',
}

export type THPDocFieldUpdateUI = {
  documentPacketId: string;
  value: string;
};

export type THPVisitationUI = {
  websiteAdvertised: string;
  websites?: string[];
};
export type THPVisitation = { websiteAdvertised: boolean; websites?: string[] };

export type THPVisitationGetUI = THPVisitationUI;
export type THPVisitationGet = {
  websiteAdvertised: boolean;
  websites?: { value: string }[];
};

export type THPContactInfoUI = {
  name: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
};
export type THPContactInfo = THPContactInfoUI;

export type TNameUpdateUI = THPDocFieldUpdateUI;
export type TNameUpdate = {
  documentPacketId: string;
  hpContactName: string | null;
};

export type TEmailUpdateUI = THPDocFieldUpdateUI;
export type TEmailUpdate = {
  documentPacketId: string;
  hpContactEmail: string | null;
};

export type TPhoneUpdateUI = THPDocFieldUpdateUI;
export type TPhoneUpdate = {
  documentPacketId: string;
  hpContactPhone: string | null;
};

export type TFaxUpdateUI = THPDocFieldUpdateUI;
export type TFaxUpdate = {
  documentPacketId: string;
  hpContactFaxNumber: string | null;
};

export type THPVisitationUpdateUI = {
  documentPacketId: string;
  hpVisitation: THPVisitationUI;
};
export type THPVisitationUpdate = {
  documentPacketId: string;
  hpVisitation: THPVisitation;
};

export type THPLetterUpdateUI = {
  hpDocumentPacketId: string;
  newHpLetters: TFile[];
  removedDocumentsIds?: string[];
};
export type THPLetterUpdate = {
  documentPacketId: string;
  newHpLetters: TFile[];
  removedHpLetterIds?: string[];
};

export type THPFormUpdateUI = {
  tenantId: string;
  hpDocumentPacketId: string;
  newHpForms: TFile[];
  removedHpFormIds?: string[];
};
export type THPFormUpdate = {
  tenantId: string;
  documentPacketId: string;
  newHpForms: TFile[];
  removedHpFormIds?: string[];
};

export type TDocPacketUI = {
  hpDocType: EHPDocPacketTypesUI;
  name: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
  websites?: string[];
  hpVisitation: string;
  hpDocPacketLetter?: TFile[];
};
export type TDocPacket = {
  hpDocType: EHPDocPacketTypes;
  hpContactInfo: THPContactInfo;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFile[];
};

export enum EDocPacketStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}
export enum EDocPacketStatusUi {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}

export type TDocPacketGetUI = {
  id: string;
  type: EHPDocPacketTypesUI;
  hpContactInfo: THPContactInfoUI;
  hpDocForms?: TFileDataTypeUI[];
  hpVisitation?: string;
  websites?: string[];
  status: EDocPacketStatusUi;
  hpDocLetters?: TFileDataTypeUI[];
  canEdit: boolean;
  isDeleted: boolean;
  receivedAfterCompletingQuestionnaireOrInterview: boolean;
};
export type TDocPacketGet = {
  type: EHPDocPacketTypes;
  hpContactInfo: THPContactInfo;
  hpDocForms?: TFileDataType[];
  hpVisitation?: THPVisitationGet;
  hpDocLetters?: TFileDataType[];
  verificationStatus: EDocPacketStatus;
  modifiable?: boolean;
  hpDocumentPacketId: string;
  isDeleted: boolean;
  receivedAfterCompletingQuestionnaireOrInterview: boolean;
};
