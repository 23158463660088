import { TFetchConverter } from 'data/types/Converter.types';
import {
  TPropertiesSearchListUI,
  TPropertyManagementDB,
  TPropertyManagementSearchListDB,
  TPropertyManagementSearchListUI,
  TPropertyManagementUI,
  TPropertySearchDB,
} from 'data/types/PropertyManagement.types';

export const getPropertyManagementSearchListConvertor: TFetchConverter<
  TPropertyManagementSearchListUI[],
  TPropertyManagementSearchListDB[]
> = {
  fromDb: data =>
    data.map(item => ({
      label: item.businessName,
      value: item.id,
    })),
};

export const propertyManagementConvertor: TFetchConverter<
  TPropertyManagementUI,
  TPropertyManagementDB
> = {
  fromDb: data => ({
    name: data.name,
    logo: data.logo,
    propertyName: data.propertyName,
    unitNumber: data.unitNumber,
  }),
};

export const getPropertiesSearchListConvertor: TFetchConverter<
  TPropertiesSearchListUI[],
  TPropertySearchDB[]
> = {
  fromDb: data =>
    (data || []).map(item => ({
      label: item.name,
      value: item.id,
    })),
};
