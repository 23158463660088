import {
  EAnimalPetChargeStatusDB,
  EAnimalPetChargeStatusUI,
  EApplicationStatus,
  EApplicationStatusUi,
  EFailType,
  EFailTypeUI,
  EGender,
  EGenderUi,
  EValueEditingStates,
  EValueEditingStatesUi,
  EVerificationStatus,
  EVerificationStatusUi,
  EVetRecordTypeEnum,
  EVetRecordTypeEnumUi,
  TEditingBooleanField,
  TEditingBooleanFieldUi,
  TEditingField,
  TEditingFieldUi,
  TEditingYesNoFieldDB,
  TEditingYesNoFieldUI,
  TFail,
  TFailUI,
  TFileDataType,
  TFileDataTypeUI,
  TOptionType,
  TOptionTypeUi,
  TPropertyInfoDB,
  TPropertyInfoUI,
  TPropertyManagementInfoDB,
  TPropertyManagementInfoUI,
  TSelectValueDB,
  TSelectValueUI,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
  TStatusInfoWithSourceDB,
  TStatusInfoWithSourceUI,
  TYesNoOptionType,
} from 'data/types/GeneralTypes';
import {
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/Converter.types';
import moment, { Moment } from 'moment';

export const phoneNumberConverter: TConvertor<string, string> = {
  toDb: phoneNumber => {
    return `+1${phoneNumber.replace(/[\s)(_-]/g, '')}`;
  },
  fromDb: phoneNumber => {
    const newNumber = phoneNumber.startsWith('+1')
      ? phoneNumber.slice(2)
      : phoneNumber;
    const x = newNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (!x) return '';
    return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
  },
};

export const fieldEditingStatesConvertor: TConvertor<
  EValueEditingStatesUi,
  EValueEditingStates
> = {
  fromDb: data => {
    return EValueEditingStatesUi[
      EValueEditingStates[data] as keyof typeof EValueEditingStatesUi
    ];
  },
  toDb: data => {
    return EValueEditingStates[
      EValueEditingStatesUi[data] as keyof typeof EValueEditingStates
    ];
  },
};

export const editingFieldConvertor: TFetchConverter<
  TEditingFieldUi,
  TEditingField
> = {
  fromDb: data => {
    return {
      value: data.value || '',
      id: (data?.predefinedId ? data.predefinedId : data.value) || '',
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
      canAdd: data.allowedOperations.some(
        item => item === EValueEditingStates.ADD,
      ),
    };
  },
};
export const editingBooleanFieldConvertor: TFetchConverter<
  TEditingBooleanFieldUi,
  TEditingBooleanField
> = {
  fromDb: data => {
    return {
      value: !!data.value,
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
    };
  },
};
export const readableFieldConvertor: TFetchConverter<TEditingFieldUi, string> =
  {
    fromDb: data => {
      return {
        value: data || '',
        id: data || '',
        canEdit: false,
        canRemove: false,
        canAdd: false,
      };
    },
  };
export const readableBooleanFieldConvertor: TFetchConverter<
  TEditingBooleanFieldUi,
  boolean
> = {
  fromDb: data => {
    return {
      value: !!data,
      canEdit: false,
    };
  },
};

export const genderEnumConvertor: TConvertor<EGenderUi, EGender> = {
  fromDb: data => {
    return EGenderUi[EGender[data] as keyof typeof EGenderUi];
  },
  toDb: data => {
    const convertedData = data.toUpperCase() as keyof typeof EGenderUi;
    return EGender[
      EGenderUi[convertedData].toUpperCase() as keyof typeof EGender
    ];
  },
};

export const selectOptionConverter: TConvertor<TOptionTypeUi, TOptionType> = {
  fromDb: data => {
    return {
      id: data.id || '',
      value: data.id || '',
      label: data.value || '',
    };
  },
  toDb: data => {
    return {
      // eslint-disable-next-line no-underscore-dangle
      ...(data.__isNew__ ? { value: data.label } : { id: data.value }),
    };
  },
};

export const selectValueConvertor: TFetchConverter<
  TSelectValueUI,
  TSelectValueDB
> = {
  fromDb: data => {
    return data?.value
      ? {
          value: data.predefinedId || data.value,
          label: data.value,
          __isNew__: !data.predefinedId,
        }
      : undefined;
  },
};

export const yesNoOptionConverter: TConvertor<
  TYesNoOptionType,
  boolean | null | undefined
> = {
  fromDb: data => {
    if (data) {
      return 'Yes';
    }
    if (data === false) {
      return 'No';
    }
    return null;
  },
  toDb: data => {
    if (!data) {
      return null;
    }
    return data === 'Yes';
  },
};

export const readableYesNoOptionFieldConvertor: TFetchConverter<
  TEditingYesNoFieldUI,
  boolean
> = {
  fromDb: data => {
    return {
      value: yesNoOptionConverter.fromDb(data),
      canEdit: false,
    };
  },
};

export const editingYesNoFieldConvertor: TFetchConverter<
  TEditingYesNoFieldUI,
  TEditingYesNoFieldDB
> = {
  fromDb: data => {
    return {
      value: yesNoOptionConverter.fromDb(data.value),
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
    };
  },
};

export const failTypeConvertor: TConvertor<EFailTypeUI, EFailType> = {
  fromDb: data => EFailTypeUI[EFailType[data] as keyof typeof EFailTypeUI],
  toDb: data => EFailType[EFailTypeUI[data] as keyof typeof EFailType],
};

export const failsConvertor: TFetchConverter<TFailUI[], TFail> = {
  fromDb: data =>
    Object.keys(data).map(key => ({
      field: key,
      reason: data[key],
    })),
};

export const vetRecordTypeEnumConvertor: TFetchConverter<
  EVetRecordTypeEnumUi,
  EVetRecordTypeEnum
> = {
  fromDb: data => {
    return data === EVetRecordTypeEnum.RABIES
      ? EVetRecordTypeEnumUi.RABIES
      : EVetRecordTypeEnumUi.VACCINATION;
  },
};

export const verificationStatusConverter: TFetchConverter<
  EVerificationStatusUi,
  EVerificationStatus
> = {
  fromDb: data => {
    const statuses: {
      [key in EVerificationStatus]: EVerificationStatusUi;
    } = {
      [EVerificationStatus.NONE]: EVerificationStatusUi.NOT_VERIFIED,
      [EVerificationStatus.VERIFIED]: EVerificationStatusUi.VERIFIED,
    };
    return statuses[data];
  },
};

export const applicationStatusConvertor: TConvertor<
  EApplicationStatusUi,
  EApplicationStatus
> = {
  fromDb: data => {
    return EApplicationStatusUi[
      EApplicationStatus[data] as keyof typeof EApplicationStatusUi
    ];
  },
  toDb: data => {
    const statuses: {
      [key in EApplicationStatusUi]: EApplicationStatus;
    } = {
      [EApplicationStatusUi.APPROVED]: EApplicationStatus.APPROVED,
      [EApplicationStatusUi.REJECTED]: EApplicationStatus.REJECTED,
      [EApplicationStatusUi.ARCHIVED]: EApplicationStatus.ARCHIVED,
      [EApplicationStatusUi.PENDING]: EApplicationStatus.PENDING,
      [EApplicationStatusUi.RETRACTED]: EApplicationStatus.RETRACTED,
    };
    return statuses[data];
  },
};

export const getFirstLetters = (data: string) => {
  if (data) {
    const matches = data.match(/\b(\w)/g);
    const acronym = matches?.join('') || '';
    return acronym;
  }
  return '';
};
export const defaultDateFormat = 'MM/DD/YYYY';
export const formattedDate = (date?: Moment | string, format?: string) => {
  return moment(date).format(format || defaultDateFormat);
};

export const propertyManagementInfoConverter: TFetchConverter<
  TPropertyManagementInfoUI,
  TPropertyManagementInfoDB
> = {
  fromDb: data => {
    return {
      propertyManagementId: data.propertyManagementId,
      propertyManagementName: data.propertyManagementName,
      propertyManagementLogo: data.propertyManagementLogo,
      firstLetters: getFirstLetters(data?.propertyManagementName || ''),
    };
  },
};

export const propertyInfoConverter: TFetchConverter<
  TPropertyInfoUI,
  TPropertyInfoDB
> = {
  fromDb: data => {
    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
    };
  },
};

export const filtersConverter: TCreateConverter<
  | {
      [key: string]: string | string[];
    }
  | undefined,
  { [key: string]: string | string[] }
> = {
  toDb: filters => {
    const filtersObj: { [key: string]: string | string[] } = {};
    if (!filters) {
      return {};
    }
    Object.keys(filters).forEach(item => {
      if (
        (Array.isArray(filters[item]) && filters[item].length) ||
        (!Array.isArray(filters[item]) && !!filters[item])
      ) {
        filtersObj[item] = filters[item];
      }
    });
    return filtersObj;
  },
};

export const fileDataTypeConverter: TFetchConverter<
  TFileDataTypeUI,
  TFileDataType
> = {
  fromDb: data => {
    return {
      name: data.file.name,
      url: data.file.url,
      createdBy: data.createdBy,
      createdDate: data.createdDate,
      esaFraudCheck: data.esaFraudCheck,
      documentFileId: data.documentFileId,
    };
  },
};
export const animalChargingStatusConverter: TConvertor<
  EAnimalPetChargeStatusUI,
  EAnimalPetChargeStatusDB
> = {
  fromDb: data => {
    const statuses: {
      [key in EAnimalPetChargeStatusDB]: EAnimalPetChargeStatusUI;
    } = {
      [EAnimalPetChargeStatusDB.BEING_CHARGED]:
        EAnimalPetChargeStatusUI.BEING_CHARGED,
      [EAnimalPetChargeStatusDB.NOT_APPLICABLE]:
        EAnimalPetChargeStatusUI.NOT_APPLICABLE,
      [EAnimalPetChargeStatusDB.NOT_BEING_CHARGED]:
        EAnimalPetChargeStatusUI.NOT_BEING_CHARGED,
      [EAnimalPetChargeStatusDB.GRACE_PERIOD]:
        EAnimalPetChargeStatusUI.GRACE_PERIOD,
      [EAnimalPetChargeStatusDB.UNSPECIFIED]:
        EAnimalPetChargeStatusUI.UNSPECIFIED,
      [EAnimalPetChargeStatusDB.SHOULD_NOT_BE_CHARGE]:
        EAnimalPetChargeStatusUI.SHOULD_NOT_BE_CHARGE,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in EAnimalPetChargeStatusUI]: EAnimalPetChargeStatusDB;
    } = {
      [EAnimalPetChargeStatusUI.BEING_CHARGED]:
        EAnimalPetChargeStatusDB.BEING_CHARGED,
      [EAnimalPetChargeStatusUI.NOT_APPLICABLE]:
        EAnimalPetChargeStatusDB.NOT_APPLICABLE,
      [EAnimalPetChargeStatusUI.NOT_BEING_CHARGED]:
        EAnimalPetChargeStatusDB.NOT_BEING_CHARGED,
      [EAnimalPetChargeStatusUI.GRACE_PERIOD]:
        EAnimalPetChargeStatusDB.GRACE_PERIOD,
      [EAnimalPetChargeStatusUI.UNSPECIFIED]:
        EAnimalPetChargeStatusDB.UNSPECIFIED,
      [EAnimalPetChargeStatusUI.SHOULD_NOT_BE_CHARGE]:
        EAnimalPetChargeStatusDB.SHOULD_NOT_BE_CHARGE,
    };
    return statuses[data];
  },
};

export const formattedDateTimezone = (
  date: Moment | string,
  format?: string,
) => {
  return moment
    .utc(date)
    .local()
    .format(format || defaultDateFormat);
};
export const statusInfoWithResourceConverter: TFetchConverter<
  TStatusInfoWithSourceUI,
  TStatusInfoWithSourceDB
> = {
  fromDb: data => {
    return {
      by: data.by,
      name: data.name,
      reason: data.reason,
      url: data.url,
      exportDate: data.exportDate
        ? formattedDate(data.exportDate, 'YYYY-MM-DD HH:mm')
        : undefined,
      date: data.date
        ? formattedDate(data.date, 'YYYY-MM-DD HH:mm')
        : undefined,
      moveOutDate: data.moveOutDate
        ? formattedDate(data.moveOutDate, 'YYYY-MM-DD')
        : undefined,
    };
  },
};
export const statusChangeInfoConverter: TFetchConverter<
  TStatusChangeInfoUI,
  TStatusChangeInfoDB
> = {
  fromDb: data => {
    return {
      by: data?.by || 'N/A',
      reason: data?.reason,
      dueDate:
        data?.dueDate &&
        formattedDateTimezone(data.dueDate, 'MM/DD/YYYY hh:mm A'),
      date: data?.date
        ? formattedDateTimezone(data.date, 'MM/DD/YYYY hh:mm A')
        : 'N/A',
      moveOutDate:
        data?.moveOutDate &&
        formattedDateTimezone(data.moveOutDate, 'MM/DD/YYYY'),
    };
  },
};
