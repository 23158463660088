import { TFilter, TPageableDataWithContentUi } from 'data/types/Table.types';
import {
  TPropertiesSearchListUI,
  TPropertyManagementSearchListDB,
  TPropertyManagementSearchListUI,
  TPropertySearchDB,
} from 'data/types/PropertyManagement.types';
import {
  getPropertiesSearchListConvertor,
  getPropertyManagementSearchListConvertor,
} from 'data/convertors/PropertyManagement.convertors';
import { EPropertyManagementUrls } from 'data/urls/propertyManagement.url';
import { authSplitApi } from '../helpers/slice.helpers';

export const PropertyManagementApi = authSplitApi('propertyManagement', [
  'propertyManagementSearchList',
  'propertiesSearchList',
]).injectEndpoints({
  endpoints: build => ({
    getPropertyManagementSearchList: build.query<
      TPropertyManagementSearchListUI[],
      TFilter
    >({
      query({ search }) {
        return {
          url: `${EPropertyManagementUrls.PROPERTY_MANAGEMENT}/${EPropertyManagementUrls.SEARCH}`,
          method: 'GET',
          params: {
            size: 2000,
            ...(search && { search }),
          },
        };
      },
      providesTags: ['propertyManagementSearchList'],
      transformResponse: (
        data: TPageableDataWithContentUi<TPropertyManagementSearchListDB[]>,
      ) => {
        const { content } = data;
        return getPropertyManagementSearchListConvertor.fromDb(content);
      },
    }),
    getPropertiesList: build.query<
      TPropertiesSearchListUI[],
      { search?: string; propertyManagementId?: string }
    >({
      query({ search, propertyManagementId }) {
        return {
          url: `${EPropertyManagementUrls.PROPERTIES}/${EPropertyManagementUrls.SEARCH}`,
          method: 'GET',
          params: {
            size: 2000,
            ...(search && { nameSearch: search }),
            ...(propertyManagementId && { propertyManagementId }),
          },
        };
      },
      providesTags: ['propertiesSearchList'],
      transformResponse: (
        data: TPageableDataWithContentUi<TPropertySearchDB[]>,
      ) => {
        const { content } = data;
        return getPropertiesSearchListConvertor.fromDb(content);
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPropertyManagementSearchListQuery,
  useGetPropertiesListQuery,
} = PropertyManagementApi;
