/* eslint-disable @typescript-eslint/no-explicit-any */
import { TIconNames } from 'components/Base/Icon';
import { FC } from 'react';

export type PublicRouteType = {
  component: FC<any>;
  path: string;
  index?: boolean;
};

export type PrivateRouteType = PublicRouteType & {
  title?: string;
  icon?: TIconNames;
  subRoutes?: PrivateRouteType[];
  show?: boolean;
  // roles: TUserRoleUi[];
};

export enum RoutesPaths {
  REDIRECT = 'redirect',
  PAGE_NOT_FOUND = '404',
  APPLICATIONS = 'applications',
  VISITATIONS = 'visitations',
  ANIMALS = 'animals',
  TENANTS = 'tenants',
}
