import SidebarLogo from 'assets/images/SidebarLogo.svg';
import { Icon, TIconNames } from 'components/Base/Icon';
import { Row, Col } from 'components/Base/Grid';
import { SMenu, SSider } from '../Layout.styles';
import { TSiderProps } from '../Layout.types';
import { useGetMenuItems } from '../Layout.hooks';

const Sider = ({ isSmallScreen, isCollapsed, onSiderClose }: TSiderProps) => {
  const { menuItems, activeKeys } = useGetMenuItems();

  return (
    <SSider
      key={Date.now()}
      breakpoint="lg"
      isSmallScreen={isSmallScreen}
      isCollapsed={isCollapsed}
    >
      <div className="imageContainer">
        <Row align="middle">
          <Col span={20}>
            <img src={SidebarLogo} alt="Logo" />
          </Col>
          <Col span={4}>
            {isSmallScreen && (
              <Icon
                onClick={onSiderClose}
                icon={TIconNames.HIGHLIGHT_OFF}
                size={20}
              />
            )}
          </Col>
        </Row>
      </div>
      <SMenu mode="inline" defaultSelectedKeys={activeKeys} items={menuItems} />
    </SSider>
  );
};

export default Sider;
