import { lazy } from 'react';
import { PrivateRouteType, PublicRouteType, RoutesPaths } from './Routes.types';

const Login = lazy(() => import('pages/public/Login'));
const RedirectLogin = lazy(() => import('pages/public/RedirectLogin'));
const PageNotFound = lazy(() => import('pages/private/PageNotFound'));
const Applications = lazy(() => import('pages/private/Applications'));
const Animals = lazy(() => import('pages/private/Animals'));
const Visitations = lazy(() => import('pages/private/Visitations'));
const Tenants = lazy(() => import('pages/private/Tenants'));

export const publicRoutes: PublicRouteType[] = [
  {
    component: Login,
    path: 'login',
  },
  {
    component: RedirectLogin,
    path: RoutesPaths.REDIRECT,
  },
];

export const privateRoutes: PrivateRouteType[] = [
  {
    title: 'Applications',
    component: Applications,
    // icon: Icon,
    path: RoutesPaths.APPLICATIONS,
    show: true,
  },
  {
    title: 'Visitations',
    component: Visitations,
    // icon: Icon,
    path: RoutesPaths.VISITATIONS,
    show: true,
  },
  {
    title: 'Tenants',
    component: Tenants,
    // icon: Icon,
    path: RoutesPaths.TENANTS,
    show: true,
  },
  {
    title: 'Animals',
    component: Animals,
    // icon: Icon,
    path: RoutesPaths.ANIMALS,
    show: true,
  },
  {
    title: '404',
    component: PageNotFound,
    path: RoutesPaths.PAGE_NOT_FOUND,
    show: false,
  },
];
