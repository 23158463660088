import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EPolicyUrls } from 'data/urls/policy.url';

export const policyApi = authSplitApi('policy', ['esaDoc']).injectEndpoints({
  endpoints: build => ({
    getDocHtml: build.query<Blob, { animalType: string; policyId: string }>({
      query({ animalType, policyId }) {
        return {
          url: `${EPolicyUrls.POLICY}/${EPolicyUrls.DOC}/${animalType}`,
          method: 'GET',
          params: { policyId },
          responseHandler: async response => {
            const text = await response.text();
            return new Blob([text], { type: 'text/html' });
          },
        };
      },
    }),
  }),
});

export const { useGetDocHtmlQuery } = policyApi;
