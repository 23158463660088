import {
  TEditingField,
  TYesNoOptionType,
  TEditingFieldUi,
} from './GeneralTypes';
import {
  TPropertyManagementDB,
  TPropertyManagementUI,
} from './PropertyManagement.types';

export enum ESortFilds {
  startDate = 'START_DATE',
  endDate = 'END_DATE',
  submittedDate = 'SUBMITTED_DATE',
}

export enum EVisitationStatusDB {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export enum EVisitationStatusUI {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Not approved',
  ARCHIVED = 'Archived',
}

export enum EAuthorizationStatusDB {
  AUTHORIZED = 'AUTHORIZED',
  UN_AUTHORIZED = 'UN_AUTHORIZED',
}

export enum EAuthorizationStatusUI {
  AUTHORIZED = 'Authorized',
  UN_AUTHORIZED = 'Unauthorized',
}

export type TVisitationDB = {
  id: string;
  tenantName: string;
  propertyName: string;
  propertyManagementName: string;
  unitNumber: string;
  startDate: string;
  endDate: string;
  submittedDate: string;
  status: EVisitationStatusDB;
  tenantProfilePicture: string | null;
  rejectReason: string | '';
};

export type TVisitationUI = {
  id: string;
  tenantName: string;
  propertyName: string;
  propertyManagementName: string;
  unitNumber: string;
  startDate: string;
  endDate: string;
  submittedDate: string;
  status: EVisitationStatusUI;
  tenantPhoto: string | null;
  rejectReason: string | '';
  firstLetters: string;
};

export type TVisitationDetailDB = {
  id: string;
  animalName: string;
  animalProfilePicture: string | null;
  species: TEditingField;
  breed?: TEditingField & { species: Omit<TEditingField, 'allowedOperations'> };
  primaryColor?: TEditingField;
  secondaryColor1?: TEditingField;
  secondaryColor2?: TEditingField;
  startDate: string;
  endDate: string;
  explanation: string;
  willBeLeftWithoutSupervision: boolean;
  precautions: string;
  didUserConfirmLearningEfforts: boolean;
  status: EVisitationStatusDB;
  isServiceAnimal: boolean;
  trainedFor: string;
  requiredBecauseOfDisability: boolean | null;
  authorization: EAuthorizationStatusDB;
  propertyManagement: TPropertyManagementDB;
  rejectReason: string | null;
  modifiable: boolean;
};

export type TVisitationDetailUI = {
  id: string;
  animalName: TEditingFieldUi;
  animalProfilePicture: TEditingFieldUi;
  species: TEditingFieldUi;
  breed: TEditingFieldUi;
  primaryColor: TEditingFieldUi;
  secondaryColor1: TEditingFieldUi;
  secondaryColor2: TEditingFieldUi;
  startDate: string;
  endDate: string;
  explanation: TEditingFieldUi;
  willBeLeftWithoutSupervision: TYesNoOptionType;
  precautions: TEditingFieldUi;
  didUserConfirmLearningEfforts: boolean;
  status: EVisitationStatusUI;
  isServiceAnimal: boolean;
  trainedFor: TEditingFieldUi;
  requiredBecauseOfDisability: TYesNoOptionType;
  authorization: EAuthorizationStatusUI;
  propertyManagement: TPropertyManagementUI;
  rejectReason: string | null;
  canEdit: boolean;
};
