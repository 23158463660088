import {
  TPageableDataWithContentUi,
  TTableFilters,
} from 'data/types/Table.types';
import {
  ESortFields,
  TApplicationAnimal,
  TApplicationAnimalUi,
  TApplicationDb,
  TApplicationDetails,
  TApplicationProppertyManagmentDB,
  TApplicationProppertyManagmentUI,
  TApplicationUI,
  TApplicationVerificationStatisticsDB,
  TApplicationVerificationStatisticsUI,
  TTApplicationDetailsUi,
} from 'data/types/Applications.types';
import { EApplicationUrls } from 'data/urls/application.url';
import {
  getApplicationsListConverter,
  getApplicationConverter,
  applicationAnimalConvertor,
  getApplicationProppertyManagmentConverter,
  getApplicationVerificationStatisticsConverter,
} from 'data/convertors/Application.converters';
import {
  TApplicationProfile,
  TApplicationProfileUI,
} from 'data/types/Tenant.types';
import { applicationProfileConvertor } from 'data/convertors/Tenant.convertors';
import { authSplitApi } from '../helpers/slice.helpers';
import {
  TAnimalVetRecordCreateUi,
  TVetRecordDocumentsUI,
  TVetRecordTypeNameUpdateUI,
  TVetRecordUI,
} from '../../data/types/Animal.types';
import { EAnimalUrls } from '../../data/urls/animal.url';
import {
  createVetRecordConvertor,
  deleteVetRecordConvertor,
  updateVetRecordDocumentsConvertor,
  vetRecordTypeUpdateConverter,
} from '../../data/convertors/Animal.converters';
import {
  TSADocPacketCreateUI,
  TSALetterUpdateUI,
} from '../../data/types/AnimalTraining.types';
import { EAnimalTrainingUrls } from '../../data/urls/animalTraining.url';
import {
  createSADocPacketsConvertor,
  saLetterUpdateConvertor,
} from '../../data/convertors/AnimalTraining.convertors';

export const applicationApi = authSplitApi('applicationReducer', [
  'applicationList',
  'application',
  'applicationAnimal',
  'applicationPropertyManagement',
  'applicationProfile',
]).injectEndpoints({
  endpoints: build => ({
    getApplicationsList: build.query<
      TPageableDataWithContentUi<TApplicationUI[]>,
      TTableFilters<{ [key: string]: string | string[] }, ESortFields>
    >({
      query({ page, pageSize, sortField, sortDirection, filters }) {
        const validFilters = getApplicationsListConverter.toDb(filters);

        return {
          url: `${EApplicationUrls.APPLICATION}?page=${page}&size=${pageSize}`,
          method: 'GET',
          params: {
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...validFilters,
          },
        };
      },
      providesTags: ['applicationList'],
      transformResponse: (
        data: TPageableDataWithContentUi<TApplicationDb[]>,
      ) => {
        return {
          ...data,
          content: getApplicationsListConverter.fromDb(data.content),
        };
      },
    }),
    getApplication: build.query<
      TTApplicationDetailsUi,
      { applicationId: string }
    >({
      query({ applicationId }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.STEP}`,
          method: 'GET',
          params: {
            applicationId,
          },
        };
      },
      transformResponse: (data: TApplicationDetails) =>
        getApplicationConverter.fromDb(data),
      providesTags: ['application'],
    }),
    getApplicationAnimal: build.query<
      TApplicationAnimalUi,
      { applicationId: string }
    >({
      query({ applicationId }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.STEP}/${EApplicationUrls.ANIMAL}`,
          method: 'GET',
          params: {
            applicationId,
          },
        };
      },
      transformResponse: (data: TApplicationAnimal) =>
        applicationAnimalConvertor.fromDb(data),
      providesTags: ['applicationAnimal'],
    }),
    updateVetRecordDocuments: build.mutation<void, TVetRecordDocumentsUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.VET_RECORDS}/${EAnimalUrls.DOCUMENTS}`,
          method: 'PATCH',
          body: updateVetRecordDocumentsConvertor.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    createVetRecord: build.mutation<void, TAnimalVetRecordCreateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.ATTACH_VET_RECORD}`,
          method: 'POST',
          body: createVetRecordConvertor.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    deleteVetRecord: build.mutation<void, TVetRecordUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.VET_RECORDS}`,
          method: 'DELETE',
          body: deleteVetRecordConvertor.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    updateVetRecordType: build.mutation<void, TVetRecordTypeNameUpdateUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.VET_RECORDS}/${EAnimalUrls.TYPE}`,
          method: 'PATCH',
          body: vetRecordTypeUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    updateSALetters: build.mutation<void, TSALetterUpdateUI>({
      query(data) {
        return {
          url: `${EAnimalTrainingUrls.SA_LETTERS}`,
          method: 'PATCH',
          body: saLetterUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['applicationAnimal'],
    }),
    createSADocPacket: build.mutation<void, TSADocPacketCreateUI>({
      query(body) {
        return {
          url: '',
          method: 'POST',
          body: createSADocPacketsConvertor.toDb(body),
        };
      },
      invalidatesTags: ['applicationAnimal'],
    }),
    getApplicationPropertyManagement: build.query<
      TApplicationProppertyManagmentUI,
      { applicationId: string }
    >({
      query({ applicationId }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.STEP}/${EApplicationUrls.PROPERTY_MANAGEMENT}`,
          method: 'GET',
          params: {
            applicationId,
          },
        };
      },
      transformResponse: (data: TApplicationProppertyManagmentDB) =>
        getApplicationProppertyManagmentConverter.fromDb(data),
      providesTags: ['applicationPropertyManagement'],
    }),

    approveApplication: build.mutation<void, { applicationId: string }>({
      query({ applicationId }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.APPROVE}`,
          method: 'PATCH',
          body: { applicationId },
        };
      },
      invalidatesTags: ['application', 'applicationList'],
    }),
    rejectApplication: build.mutation<
      void,
      { applicationId: string; rejectReason: string }
    >({
      query({ applicationId, rejectReason }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.REJECT}`,
          method: 'PATCH',
          body: { applicationId, rejectReason },
        };
      },
      invalidatesTags: ['application', 'applicationList'],
    }),
    getApplicationVerificationStatistics: build.query<
      TApplicationVerificationStatisticsUI,
      { applicationId: string }
    >({
      query({ applicationId }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.VERIFICATION}/${EApplicationUrls.STATISTICS}`,
          method: 'GET',
          params: { applicationId },
        };
      },
      transformResponse: (data: TApplicationVerificationStatisticsDB) =>
        getApplicationVerificationStatisticsConverter.fromDb(data),
      providesTags: ['applicationVerificationStatistics'],
    }),
    getApplicationProfile: build.query<
      TApplicationProfileUI,
      { applicationId?: string }
    >({
      query({ applicationId }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.STEP}/${EApplicationUrls.TENANT}`,
          method: 'GET',
          params: { applicationId },
        };
      },
      transformResponse: (data: TApplicationProfile) =>
        applicationProfileConvertor.fromDb(data),
      providesTags: ['applicationProfile'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetApplicationsListQuery,
  useCreateSADocPacketMutation,
  useGetApplicationQuery,
  useGetApplicationAnimalQuery,
  useUpdateSALettersMutation,
  useCreateVetRecordMutation,
  useUpdateVetRecordTypeMutation,
  useUpdateVetRecordDocumentsMutation,
  useDeleteVetRecordMutation,
  useGetApplicationPropertyManagementQuery,
  useGetApplicationVerificationStatisticsQuery,
  useApproveApplicationMutation,
  useRejectApplicationMutation,
  useGetApplicationProfileQuery,
} = applicationApi;
