import { EAnimalUrls } from 'data/urls/animal.url';
import {
  TPageableDataWithContentUi,
  TTableFilters,
} from 'data/types/Table.types';
import {
  ESortFields,
  TAnimalDB,
  TAnimalDetail,
  TAnimalDetailUi,
  TAnimalUI,
  TVetRecordExpirationUpdateUI,
  TAnimalWarningVerificationDB,
  TAnimalWarningVerificationUI,
  TUpdateAnimalTypeUI,
  TApproveAnimalTypeConvUI,
  TRejectAnimalTypeConvUI,
  TAnimalFollowUpUI,
  TChargingStatusUpdateUI,
  TChargingStatusUpdateDB,
  TMarkAsPNCUI,
  TMarkAsPNCDB,
  TRemoveApplicantDiscoveredUI,
  TRemoveApplicantDiscoveredDB,
  TMarkAsDiscoverdDB,
  TMarkAsDiscoverdUI,
  TMarkAsDiscoverdApplicantDB,
  TMarkAsDiscoverdApplicantUI,
} from 'data/types/Animal.types';
import {
  animalConverter,
  getAnimalConverter,
  vetRecordExpirationUpdateConverter,
  getAnimalWarningVerificationConverter,
  updateAnimalTypeConverter,
  approveAnimalTypeConversionConverter,
  rejectAnimalTypeConversionConverter,
  followUpAnimalConverter,
  updatePetChargingStatusConverter,
  markAsPNCConverter,
  removeApplicantDiscoveredConverter,
  markAsDiscoveredAnimalConverter,
  markAsDiscoveredApplicantAnimalConverter,
} from 'data/convertors/Animal.converters';
import { authSplitApi } from '../helpers/slice.helpers';

export const animalApi = authSplitApi('animal', [
  'animal',
  'animalsList',
]).injectEndpoints({
  endpoints: build => ({
    verifyAnimal: build.mutation<void, { animalId: string }>({
      query({ animalId }) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.VERIFY}`,
          method: 'POST',
          body: { animalId },
        };
      },
      invalidatesTags: ['animal'],
    }),
    followUpAnimal: build.mutation<void, TAnimalFollowUpUI>({
      query(data) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.FOLLOW_UP}/${EAnimalUrls.ITEM}`,
          method: 'POST',
          body: followUpAnimalConverter.toDb(data),
        };
      },
      invalidatesTags: ['animal'],
    }),
    completeFollowUpAnimal: build.mutation<void, TAnimalFollowUpUI>({
      query(data) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.FOLLOW_UP}/${EAnimalUrls.COMPLETE}`,
          method: 'PATCH',
          body: followUpAnimalConverter.toDb(data),
        };
      },
      invalidatesTags: ['animal'],
    }),
    restartFollowUpAnimal: build.mutation<void, { animalId: string }>({
      query({ animalId }) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.FOLLOW_UP}/${EAnimalUrls.RESTART}`,
          method: 'PATCH',
          body: { animalId },
        };
      },
      invalidatesTags: ['animal'],
    }),
    verifyAnimalWarning: build.query<
      TAnimalWarningVerificationUI,
      { animalId: string }
    >({
      query({ animalId }) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.VERIFY}/${EAnimalUrls.WARNING}`,
          method: 'GET',
          params: { animalId },
        };
      },
      providesTags: ['animal'],
      transformResponse: (data: TAnimalWarningVerificationDB) =>
        getAnimalWarningVerificationConverter.fromDb(data),
    }),
    retractAnimalVerification: build.mutation<void, { animalId: string }>({
      query({ animalId }) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.VERIFY}`,
          method: 'DELETE',
          body: { animalId },
        };
      },
      invalidatesTags: ['animal'],
    }),
    verifyAnimaVetRecord: build.mutation<
      void,
      { animalId: string; vetRecordId: string }
    >({
      query({ animalId, vetRecordId }) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.VET_RECORD}/${EAnimalUrls.VERIFY}`,
          method: 'POST',
          body: { animalId, vetRecordId },
        };
      },
      invalidatesTags: ['animal'],
    }),
    undoVerifyAnimaVetRecord: build.mutation<
      void,
      { animalId: string; vetRecordId: string }
    >({
      query({ animalId, vetRecordId }) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.VET_RECORD}/${EAnimalUrls.VERIFY}`,
          method: 'DELETE',
          body: { animalId, vetRecordId },
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalType: build.mutation<void, TUpdateAnimalTypeUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.TYPE}`,
          method: 'PATCH',
          body: updateAnimalTypeConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals'],
    }),
    approveAnimalTypeConversion: build.mutation<void, TApproveAnimalTypeConvUI>(
      {
        query(body) {
          return {
            url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.CONVERSION}/${EAnimalUrls.APPROVE}`,
            method: 'PATCH',
            body: approveAnimalTypeConversionConverter.toDb(body),
          };
        },
        invalidatesTags: ['animal', 'animals'],
      },
    ),
    rejectAnimalTypeConversion: build.mutation<void, TRejectAnimalTypeConvUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.CONVERSION}/${EAnimalUrls.REJECT}`,
          method: 'PATCH',
          body: rejectAnimalTypeConversionConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals'],
    }),
    updateVetRecordExpiration: build.mutation<
      void,
      TVetRecordExpirationUpdateUI
    >({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.VET_RECORDS}`,
          method: 'PUT',
          body: vetRecordExpirationUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals'],
    }),
    verifySADocPacket: build.mutation<
      void,
      { animalId: string; saDocumentPacketId: string }
    >({
      query({ animalId, saDocumentPacketId }) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.SA_DOC_PACKET}/${EAnimalUrls.VERIFY}`,
          method: 'POST',
          body: { animalId, saDocumentPacketId },
        };
      },
      invalidatesTags: ['animal'],
    }),
    undoVerifySADocPacket: build.mutation<
      void,
      { animalId: string; saDocumentPacketId: string }
    >({
      query({ animalId, saDocumentPacketId }) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.SA_DOC_PACKET}/${EAnimalUrls.VERIFY}`,
          method: 'DELETE',
          body: { animalId, saDocumentPacketId },
        };
      },
      invalidatesTags: ['animal'],
    }),
    getAnimal: build.query<TAnimalDetailUi, string>({
      query(id) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['animal'],
      transformResponse: (data: TAnimalDetail) =>
        getAnimalConverter.fromDb(data),
    }),
    getAnimalsList: build.query<
      TPageableDataWithContentUi<TAnimalUI[]>,
      TTableFilters<{ [key: string]: string | string[] }, ESortFields>
    >({
      query({ page, pageSize, sortField, sortDirection, filters }) {
        const validFilters = animalConverter.toDb(filters);
        return {
          url: `${EAnimalUrls.ANIMAL}?page=${page}&size=${pageSize}`,
          method: 'GET',
          params: {
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...validFilters,
          },
        };
      },
      providesTags: ['animalsList'],
      transformResponse: (data: TPageableDataWithContentUi<TAnimalDB[]>) => {
        return {
          ...data,
          content: data.content.map(item => animalConverter.fromDb(item)),
        };
      },
    }),
    viewNewUpdates: build.mutation<void, string>({
      query(id) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${id}/${EAnimalUrls.VIEW}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['animalsList'],
    }),
    updateChargingStatus: build.mutation<
      TChargingStatusUpdateDB,
      TChargingStatusUpdateUI
    >({
      query(body: TChargingStatusUpdateUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.CHARGING_STATUS}`,
          method: 'PATCH',
          body: updatePetChargingStatusConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    markAsPNC: build.mutation<TMarkAsPNCDB, TMarkAsPNCUI>({
      query(body: TMarkAsPNCUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.TAGS}/${EAnimalUrls.PNC}`,
          method: 'POST',
          body: markAsPNCConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    markAsKnown: build.mutation<TMarkAsPNCDB, TMarkAsPNCUI>({
      query(body: TMarkAsPNCUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.TAGS}/${EAnimalUrls.KNOWN}`,
          method: 'POST',
          body: markAsPNCConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    removePNC: build.mutation<TMarkAsPNCDB, TMarkAsPNCUI>({
      query(body: TMarkAsPNCUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.TAGS}/${EAnimalUrls.PNC}`,
          method: 'DELETE',
          body: markAsPNCConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    removeKnown: build.mutation<TMarkAsPNCDB, TMarkAsPNCUI>({
      query(body: TMarkAsPNCUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.TAGS}/${EAnimalUrls.KNOWN}`,
          method: 'DELETE',
          body: markAsPNCConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    removeDiscoverd: build.mutation<TMarkAsPNCDB, TMarkAsPNCUI>({
      query(body: TMarkAsPNCUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.TAGS}/${EAnimalUrls.DISCOVERED}`,
          method: 'DELETE',
          body: markAsPNCConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    removeApplicantDiscoverd: build.mutation<
      TRemoveApplicantDiscoveredDB,
      TRemoveApplicantDiscoveredUI
    >({
      query(body: TRemoveApplicantDiscoveredUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.APPLICANT}/${EAnimalUrls.TAGS}/${EAnimalUrls.DISCOVERED}`,
          method: 'DELETE',
          body: removeApplicantDiscoveredConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    markAsDiscoverd: build.mutation<TMarkAsDiscoverdDB, TMarkAsDiscoverdUI>({
      query(body: TMarkAsDiscoverdUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.TAGS}/${EAnimalUrls.DISCOVERED}`,
          method: 'POST',
          body: markAsDiscoveredAnimalConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    markAsDiscoverdApplicant: build.mutation<
      TMarkAsDiscoverdApplicantDB,
      TMarkAsDiscoverdApplicantUI
    >({
      query(body: TMarkAsDiscoverdApplicantUI) {
        return {
          url: `${EAnimalUrls.ANIMAL}/${EAnimalUrls.APPLICANT}/${EAnimalUrls.TAGS}/${EAnimalUrls.DISCOVERED}`,
          method: 'POST',
          body: markAsDiscoveredApplicantAnimalConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useVerifyAnimalMutation,
  useFollowUpAnimalMutation,
  useCompleteFollowUpAnimalMutation,
  useRestartFollowUpAnimalMutation,
  useRetractAnimalVerificationMutation,
  useVerifyAnimaVetRecordMutation,
  useVerifySADocPacketMutation,
  useVerifyAnimalWarningQuery,
  useUpdateAnimalTypeMutation,
  useApproveAnimalTypeConversionMutation,
  useRejectAnimalTypeConversionMutation,
  useUndoVerifyAnimaVetRecordMutation,
  useUpdateVetRecordExpirationMutation,
  useUndoVerifySADocPacketMutation,
  useGetAnimalsListQuery,
  useGetAnimalQuery,
  useViewNewUpdatesMutation,
  useUpdateChargingStatusMutation,
  useMarkAsPNCMutation,
  useRemovePNCMutation,
  useMarkAsKnownMutation,
  useRemoveKnownMutation,
  useRemoveDiscoverdMutation,
  useRemoveApplicantDiscoverdMutation,
  useMarkAsDiscoverdApplicantMutation,
  useMarkAsDiscoverdMutation,
} = animalApi;
