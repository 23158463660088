import moment, { Moment } from 'moment';

export const defaultDateFormat = 'MM/DD/YYYY';
export const dateTimeFormat = 'MM/DD/YYYY HH:mm A';

export const formattedDate = (date?: Moment | string, format?: string) => {
  return moment(date).format(format || defaultDateFormat);
};

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
            .mx-${i} {
                margin: 0 ${n}px !important;
            }
            .my-${i} {
                margin: ${n}px 0 !important;
            }
            .m-${i} {
                margin: ${n}px !important;
            }
            .mt-${i} {
                margin-top: ${n}px !important;
            }
            .mb-${i} {
                margin-bottom: ${n}px !important;
            }
            .ml-${i} {
                margin-left: ${n}px !important;
            }
            .mr-${i} {
                margin-right: ${n}px !important;
            }
            .pt-${i} {
                padding-top: ${n}px !important;
            }
            .pb-${i} {
                padding-bottom: ${n}px !important;
            }
            .pl-${i} {
                padding-left: ${n}px !important;
            }
            .pr-${i} {
                padding-right: ${n}px !important;
            }
            .px-${i} {
                padding: 0 ${n}px !important;
            }
            .py-${i} {
                padding: ${n}px 0 !important;
            }
            .p-${i} {
                padding: ${n}px !important;
            }
        `;
  });

  return classNames;
};

export const getReadableDuration = (
  startDate: number | string | Moment,
  endDate: number | string | Moment,
) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const duration = moment.duration(end.diff(start));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = '';

  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''}, `;
  }
  if (months > 0) {
    result += `${months} month${months > 1 ? 's' : ''}, `;
  }
  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''}, `;
  }
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''}, `;
  }
  if (minutes > 0) {
    result += `${minutes} minute${minutes > 1 ? 's' : ''}, `;
  }
  if (seconds > 0) {
    result += `${seconds} second${seconds > 1 ? 's' : ''}`;
  }

  // Trim trailing comma and space
  return result.replace(/,\s*$/, '');
};
