import { Icon, TIconNames } from 'components/Base/Icon';
import { SHeader } from '../Layout.styles';
import { THeaderProps } from '../Layout.types';

const Header = ({ isCollapsed, onSiderOpen }: THeaderProps) => {
  return (
    <SHeader>
      {isCollapsed && (
        <Icon onClick={onSiderOpen} icon={TIconNames.MENU} size={18} />
      )}
    </SHeader>
  );
};

export default Header;
