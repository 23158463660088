import { Route, Routes, Navigate, useNavigate, Outlet } from 'react-router-dom';
import React, { Suspense, useEffect, FC } from 'react';
import Layout from 'components/Base/Layout';
import { privateRoutes } from './RoutesData';
import { RoutesPaths } from './Routes.types';

const PrivateRoutes: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn]);
  return (
    <Suspense fallback={<Layout />}>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="*"
            element={<Navigate replace to={RoutesPaths.PAGE_NOT_FOUND} />}
          />
          {privateRoutes.map(({ path, component, subRoutes }) => {
            const Component = component;
            if (!subRoutes?.length) {
              return (
                <Route key={path} path={`${path}/*`} element={<Component />} />
              );
            }
            return (
              <Route
                key={path}
                path={`${path}/*`}
                element={
                  <Routes>
                    <Route path="/" element={<Outlet />}>
                      <Route path="*" element={<Navigate to="/app/404" />} />
                      {subRoutes?.map(
                        ({ path: subRoute, component: subComponent }) => {
                          const SubComponent = subComponent;
                          const subPath = subRoute.replace(path, '');
                          return (
                            <Route
                              key={subPath}
                              path={subPath}
                              element={<SubComponent />}
                            />
                          );
                        },
                      )}
                      )
                    </Route>
                  </Routes>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PrivateRoutes;
