export enum EAnimalTagDB {
  PNC = 'PNC',
  KNOWN = 'KNOWN',
  DISCOVERED = 'DISCOVERED',
}

export enum EAnimalTagUI {
  PNC = 'PNC',
  KNOWN = 'Known',
  DISCOVERED = 'Discovered',
}

export enum ETenantTagDB {
  KNOWN = 'KNOWN',
}

export enum ETenantTagUI {
  KNOWN = 'Known',
}
