import {
  TAuthConverter,
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/Converter.types';
import {
  TDocPacketUI,
  TDocPacket,
  THPVisitation,
  THPVisitationUI,
  THPContactInfoUI,
  THPContactInfo,
  TNameUpdateUI,
  TNameUpdate,
  TEmailUpdateUI,
  TEmailUpdate,
  TPhoneUpdateUI,
  TPhoneUpdate,
  TFaxUpdateUI,
  TFaxUpdate,
  THPVisitationUpdateUI,
  THPVisitationUpdate,
  EHPDocPacketTypes,
  EHPDocPacketTypesUI,
  EHPVisitation,
  EHPVisitationUI,
  TDocPacketGetUI,
  TDocPacketGet,
  THPLetterUpdateUI,
  THPLetterUpdate,
  THPFormUpdateUI,
  THPFormUpdate,
  EDocPacketStatusUi,
  EDocPacketStatus,
} from 'data/types/DocPacket.types';
import { fileDataTypeConverter } from './General.converters';

export const docPacketStatusConverter: TConvertor<
  EDocPacketStatusUi,
  EDocPacketStatus
> = {
  fromDb: data => {
    const statuses: {
      [key in EDocPacketStatus]: EDocPacketStatusUi;
    } = {
      [EDocPacketStatus.NONE]: EDocPacketStatusUi.NONE,
      [EDocPacketStatus.VERIFIED]: EDocPacketStatusUi.VERIFIED,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in EDocPacketStatusUi]: EDocPacketStatus;
    } = {
      [EDocPacketStatusUi.NONE]: EDocPacketStatus.NONE,
      [EDocPacketStatusUi.VERIFIED]: EDocPacketStatus.VERIFIED,
    };
    return statuses[data];
  },
};

export const hpDocTypeConvertor: TConvertor<
  EHPDocPacketTypesUI,
  EHPDocPacketTypes
> = {
  fromDb: data => {
    return EHPDocPacketTypesUI[
      EHPDocPacketTypes[data] as keyof typeof EHPDocPacketTypesUI
    ];
  },
  toDb: data => {
    return EHPDocPacketTypes[
      EHPDocPacketTypesUI[data] as keyof typeof EHPDocPacketTypes
    ];
  },
};

export const hpVisitationTypeConvertor: TConvertor<
  EHPVisitationUI,
  EHPVisitation
> = {
  fromDb: data => {
    return EHPVisitationUI[EHPVisitation[data] as keyof typeof EHPVisitationUI];
  },
  toDb: data => {
    return EHPVisitation[EHPVisitationUI[data] as keyof typeof EHPVisitation];
  },
};

export const hpVisitationConvertor: TCreateConverter<
  THPVisitationUI,
  THPVisitation
> = {
  toDb: data => ({
    websiteAdvertised: data?.websiteAdvertised === 'yes',
    websites: data.websites?.filter(website => !!website),
  }),
};

export const hpContactInfoConvertor: TAuthConverter<
  THPContactInfoUI,
  THPContactInfo,
  THPContactInfoUI,
  THPContactInfo
> = {
  toDb: data => ({
    name: data.name,
    emailAddress: data.emailAddress?.trim() || undefined,
    phoneNumber: data.phoneNumber
      ? `${data.phoneNumber[0] === '+' ? '' : '+'}${data.phoneNumber}`
      : undefined,
    faxNumber: data.faxNumber?.trim() || undefined,
  }),
  fromDb: data => ({
    name: data.name,
    emailAddress: data.emailAddress,
    phoneNumber: data.phoneNumber,
    faxNumber: data.faxNumber,
  }),
};

export const hpNameUpdateConvertor: TCreateConverter<
  TNameUpdateUI,
  TNameUpdate
> = {
  toDb: data => ({
    documentPacketId: data.documentPacketId,
    hpContactName: data.value || null,
  }),
};

export const hpEmailUpdateConvertor: TCreateConverter<
  TEmailUpdateUI,
  TEmailUpdate
> = {
  toDb: data => ({
    documentPacketId: data.documentPacketId,
    hpContactEmail: data.value || null,
  }),
};

export const hpPhoneUpdateConvertor: TCreateConverter<
  TPhoneUpdateUI,
  TPhoneUpdate
> = {
  toDb: data => ({
    documentPacketId: data.documentPacketId,
    hpContactPhone: data.value
      ? `${data.value[0] === '+' ? '' : '+'}${data.value}`
      : null,
  }),
};

export const hpFaxUpdateConvertor: TCreateConverter<TFaxUpdateUI, TFaxUpdate> =
  {
    toDb: data => ({
      documentPacketId: data.documentPacketId,
      hpContactFaxNumber: data.value || null,
    }),
  };

export const hpVisitationUpdateConvertor: TCreateConverter<
  THPVisitationUpdateUI,
  THPVisitationUpdate
> = {
  toDb: data => ({
    documentPacketId: data.documentPacketId,
    hpVisitation: hpVisitationConvertor.toDb(data.hpVisitation),
  }),
};

export const hpLetterUpdateConvertor: TCreateConverter<
  THPLetterUpdateUI,
  THPLetterUpdate
> = {
  toDb: data => ({
    documentPacketId: data.hpDocumentPacketId,
    newHpLetters: data.newHpLetters || [],
    removedHpLetterIds: data.removedDocumentsIds || [],
  }),
};
export const hpFormUpdateConvertor: TCreateConverter<
  THPFormUpdateUI,
  THPFormUpdate
> = {
  toDb: data => ({
    tenantId: data.tenantId,
    documentPacketId: data.hpDocumentPacketId,
    newHpForms: data.newHpForms || [],
    removedHpFormIds: data.removedHpFormIds || [],
  }),
};

export const getHPDocPacketsConvertor: TFetchConverter<
  TDocPacketGetUI,
  TDocPacketGet
> = {
  fromDb: data => {
    return {
      id: data.hpDocumentPacketId,
      isDeleted: data.isDeleted,
      status: docPacketStatusConverter.fromDb(data.verificationStatus),
      hpDocumentPacketId: data.hpDocumentPacketId,
      type: hpDocTypeConvertor.fromDb(data.type),
      hpContactInfo: hpContactInfoConvertor.fromDb(data.hpContactInfo),
      websites: data.hpVisitation?.websites?.map(item => item?.value),
      hpVisitation: data.hpVisitation?.websiteAdvertised ? 'yes' : 'no',
      hpDocForms: (data.hpDocForms || []).map(file =>
        fileDataTypeConverter.fromDb(file),
      ),
      hpDocLetters: (data.hpDocLetters || []).map(file =>
        fileDataTypeConverter.fromDb(file),
      ),
      verificationStatus: data.verificationStatus,
      canEdit: data.modifiable || false,
      receivedAfterCompletingQuestionnaireOrInterview:
        data.receivedAfterCompletingQuestionnaireOrInterview,
    };
  },
};

export const createHPDocConvertor: TCreateConverter<TDocPacketUI, TDocPacket> =
  {
    toDb: data => ({
      hpContactInfo: hpContactInfoConvertor.toDb({
        name: data.name,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
        faxNumber: data.faxNumber,
      }),
      hpDocType: hpDocTypeConvertor.toDb(data.hpDocType),
      hpVisitation: hpVisitationConvertor.toDb({
        websiteAdvertised: data.hpVisitation,
        websites: data.websites?.filter(item => !!item),
      }),
      hpDocPacketLetter: data.hpDocPacketLetter,
    }),
  };
