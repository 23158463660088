import {
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Screens,
} from 'core/CssVariables';
import { createGlobalStyle, ThemeProps } from 'styled-components';

import { generateSpaceClassNames } from 'helpers/utils';
import { TThemeProps } from 'theme/theme';

const spaces = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40];

const GlobalStyles = createGlobalStyle<ThemeProps<TThemeProps>>`
  /*spacing*/
  input {
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      display: none;
      background: yellow;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  .bold-link {
    font-weight: ${FontWeights.SemiBold} !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
  ${() => generateSpaceClassNames(spaces)}
  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .text-underline {
    text-decoration: underline
  }

  .h-100 {
    height: 100%;
  }

  .w-100 {
    width: 100%;
  }

  .position-absolute {
    position: absolute;
  }

  .t-0 {
    top: 0
  }

  .r-0 {
    right: 0;
  }

  .l-0 {
    left: 0
  }

  .b-0 {
    bottom: 0
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .ant-image-preview-img {
    max-width: 60%;
    width: 100%;
    @media (max-width: ${Screens.ScreensMD}) {
    max-width: 100%;
    }
  }


  .container-lg {
    width: 1320px;
    max-width: 100%;
    margin: 0 auto;
    @media (max-width: ${Screens.ScreensMD}) {
      width: 100%;
    }
  }

  .container-md {
    width: 637px;
    max-width: 100%;
    margin: 0 auto;
    @media (max-width: ${Screens.ScreensMD}) {
      width: 100%;
    }
  }

  /*select*/
  .ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
      font-weight: 400;
    }
  }

  /*timepicker*/
  .ant-picker-ranges {
    .ant-picker-ok {
      .ant-btn {
        height: 24px;
        padding: 0 7px;
      }
    }
  }

  .ant-picker-time-panel-column {
    &:after {
      height: auto;
    }
  }

  .white-space {
    white-space: nowrap;
  }

  .word-break-all {
    word-break: break-all;
  }

  .word-break {
    word-break: break-word;
  }

  .flex-wrap-nowrap {
    flex-wrap: nowrap;
  }
  
  .pointer {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background: ${({ theme }) => theme.scrollbar.track};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar.thumb};
    border-radius: 8px;
  }

  @media (max-width: ${Screens.ScreensMD}) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  label {
    color: ${({ theme }) => theme.secondaryColor};
    font-size: ${FontSizes.FontXS}px;
    font-weight: ${FontWeights.Light};
  }

  .ant-form {
    &.ant-form-vertical {
      .ant-form-item-label {
        padding: 0 0 4px;
      }
    }

    & .ant-form-item-control-input {
      min-height: 20px;
    }

    & .ant-form-item-has-error .react-tel-input {
      & .form-control, & .flag-dropdown {
        border-color: ${({ theme }) => theme.input.errorBorderColor};
      }
    }

    .ant-form-item-has-error {
      .css-1s2u09g-control, .css-1pahdxg-control {
        border-color: ${({ theme }) => theme.input.errorBorderColor};
      }
    }

    & .ant-form-item-label {
      label {
        color: ${Colors.LightGrey};
        font-size: ${FontSizes.FontXS}px;
        font-weight: ${FontWeights.Regular};
      }
    }

    & .ant-form-item-explain-error {
      color: ${({ theme }) => theme.input.errorBorderColor};
      font-size: ${FontSizes.FontXS}px;
      font-weight: ${FontWeights.Regular};
    }
  }


  .secondary-text {
    color: ${({ theme }) => theme.secondaryColor} !important;

    .ant-typography {
      color: ${({ theme }) => theme.secondaryColor} !important;
    }
  }

  .secondary-icon {
    path {
      fill: ${({ theme }) => theme.secondaryColor} !important;
    }
  }
`;

export default GlobalStyles;

const DropDownGlobalStyle = createGlobalStyle<ThemeProps<TThemeProps>>`
  .ant-select-dropdown, .ant-table-filter-dropdown, .ant-dropdown-menu {
    ::-webkit-scrollbar {
      width: 4px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background: ${({ theme }) => theme.scrollbar.track};
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.scrollbar.thumb};
      border-radius: 8px;
    }

    max-height: 258px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.dropdown.backgroundColor};
    border: 1px solid ${({ theme }) => theme.dropdown.borderColor};
    box-shadow: 0 4px 12px ${({ theme }) => theme.dropdown.boxShadowColor};
    padding: 0;
    border-radius: 8px;

    .rc-virtual-list-scrollbar-thumb {
      width: 4px !important;
      left: 4px !important;
      background: ${({ theme }) => theme.scrollbar.thumb} !important;
    }

    .ant-dropdown-menu-item {
      padding: 12px 12px 12px 6px;
    }

    .ant-select-item-option, .ant-dropdown-menu-item,
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: transparent;

      &.select-all .ant-select-item-option-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &:hover {
        background-color: ${() => Colors.BackgroundColor};

        .ant-select-item-option-content {
          color: ${Colors.Black};
        }
      }
    }

    &.all-selected .ant-select-item-option[title="Select all"] {
      background-color: ${({ theme }) => theme.dropdown.selectedItemColor};;
    }

    & .ant-select-item.ant-select-item-option-selected, .ant-select-item.ant-dropdown-menu-item-selected {
      background-color: ${({ theme }) => theme.dropdown.selectedItemColor};

      &:hover {
        background-color: ${({ theme }) =>
          theme.dropdown.selectedItemHoverColor};
      }

      & .ant-select-item-option-state {
        display: none;
      }
    }

    .ant-select-item-option-content, .ant-dropdown-menu-item {
      color: ${Colors.Black};
      &-disabled {
        .ant-dropdown-menu-title-content {
          .ant-typography {
            color: ${Colors.LightGrey}
          }
          .icon {
            path {
              fill: ${Colors.LightGrey}
            }
          }
        }
      }

      .ant-dropdown-menu-title-content {
        > span {
          margin-left: 8px;
        }
      }
    }

    .ant-table-filter-dropdown-btns {
      border: none;
    }

    .ant-empty {
      .ant-empty-description {
        color: ${({ theme }) => theme.color};
      }
    }

    .menu-item-ticket-filter {
      padding: 8px 12px;

      :hover {
        background-color: ${Colors.PrimaryColor}
      }

      &.ant-dropdown-menu-item {
        &-disabled{
          :hover {
            background-color:${Colors.Transparent} ;
          }
        }
      }

      span {
        width: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .ant-select-dropdown-empty {
    & .ant-empty .ant-empty-image {
      display: none;
    }
  }

  .ant-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: ${({ theme }) => theme.tooltip.backgroundColor};
        color: ${({ theme }) => theme.tooltip.color};
        border-radius: 8px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
      }
    }
  }

  .custom-tooltip.ant-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: ${({ theme }) =>
          theme.timeEntryTooltip.backgroundColor};

      }
    }

    .ant-tooltip-arrow-content {
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) =>
        theme.timeEntryTooltip.backgroundColor};
    }
  }

  .ant-dropdown {
    .ant-table-filter-dropdown {
      background: ${({ theme }) => theme.table.filterBackgroundColor};
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);

      .ant-table-filter-dropdown-search {
        border: none;

        .ant-table-filter-dropdown-search-input, .ant-input, .ant-input-prefix, .anticon {
          background-color: ${({ theme }) => theme.input.backgroundColor};
          color: ${({ theme }) => theme.input.textColorPrimary};
          border-color: ${({ theme }) => theme.input.borderColor};
        }
      }

      .ant-table-filter-dropdown-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ant-btn-primary {
          width: 59px;
          height: 32px;
          background-color: ${Colors.Blue};
          border-color: ${Colors.Blue};
        }

        .ant-btn-link {
          color: ${Colors.PrimaryColor};
        }

      }

      .ant-dropdown-menu {
        background: ${({ theme }) => theme.table.filterBackgroundColor};

        .ant-dropdown-menu-item {
          .ant-dropdown-menu-title-content {
            color: ${({ theme }) => theme.table.checkboxLabelColor};

            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border-radius: 4px;
                  border: 1px solid ${({ theme }) =>
                    theme.table.checkboxBorderColor};
                  background-color: transparent;

                  &::after {
                    border-width: 3px;
                  }
                }

                &.ant-checkbox-indeterminate {
                  .ant-checkbox-inner {
                    &::after {
                      width: 8px;
                      height: 3px;
                      background-color: ${({ theme }) =>
                        theme.table.backgroundColor};
                    }
                  }
                }

                &.ant-checkbox-checked {
                  &::after {
                    border: none;
                    border-color: ${({ theme }) => theme.table.backgroundColor};
                  }

                  .ant-checkbox-inner {
                    &::after {
                      border-color: ${({ theme }) =>
                        theme.table.backgroundColor};
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-picker-dropdown {
    .ant-picker-range-arrow {
      &::after {
        border-top-color: ${({ theme }) => theme.dropdown.backgroundColor};
        border-right-color: ${({ theme }) => theme.dropdown.backgroundColor};
      }
    }

    .ant-picker-panel-container {
      background-color: ${({ theme }) => theme.dropdown.backgroundColor};
      border: 1px solid ${({ theme }) => theme.dropdown.borderColor};
      box-shadow: 0 4px 12px ${({ theme }) => theme.dropdown.boxShadowColor};

      .ant-picker-panel {
        border-width: 0;

        .ant-picker-footer {
          border: none;

          .ant-picker-today-btn {
            color: ${Colors.PrimaryColor}
          }
        }

        .ant-picker-time-panel {
          border: none;

          .ant-picker-content {
            .ant-picker-time-panel-column {
              border: none;

              ::-webkit-scrollbar {
                width: 4px;
                height: 6px;
              }

              ::-webkit-scrollbar-track {
                border-radius: 8px;
                background: ${({ theme }) => theme.scrollbar.track};
              }

              ::-webkit-scrollbar-thumb {
                background: ${({ theme }) => theme.scrollbar.thumb};
                border-radius: 8px;
              }

              .ant-picker-time-panel-cell {
                .ant-picker-time-panel-cell-inner {
                  color: ${({ theme }) => theme.color};

                  &:hover {
                    background-color: ${Colors.White};
                  }
                }
              }

              .ant-picker-time-panel-cell-selected {
                .ant-picker-time-panel-cell-inner {
                  color: ${Colors.PrimaryColor};
                  font-weight: ${FontWeights.SemiBold};
                  background-color: ${Colors.White};
                }
              }
            }
          }
        }

      }

      .ant-picker-cell {
        color: ${Colors.LightGrey};
      }

      .ant-picker-cell-today {
        .ant-picker-cell-inner {
          &::before {
            border-color: ${Colors.PrimaryColor};
          }
        }
      }

      .ant-picker-cell-disabled::before {
        background-color: ${({ theme }) =>
          theme.datePicker.disabledBackgroundColor};
      }

      .ant-picker-cell-in-view {
        color: ${({ theme }) => theme.color};

        .ant-picker-cell-inner {
          &:hover {
            background-color: ${Colors.PrimaryColor};
            color: ${Colors.Black};
          }
        }
      }

      .ant-picker-header {
        color: ${({ theme }) => theme.color};
        border: none;

        button {
          color: ${Colors.LightGrey};
        }
      }

      .ant-picker-body {
        table {
          thead {
            tr > th {
              color: ${({ theme }) => theme.color};
            }
          }
        }
      }

      @media (max-width: ${Screens.ScreensSM}) {
        .ant-picker-panels {
          flex-direction: column;
        }
      }

    }

  }

  .profile-menu {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        padding: 16px 48px 16px 16px;
        font-weight: 500;
        color: ${Colors.Black};
        font-size: 16px;

        :hover {
          background-color: #f5f7f9;
        }

        .ant-dropdown-menu-title-content {
          font-weight: ${FontSizes.FontMD};
        }
      }
    }
  }

  .ant-notification-notice {
    border: 1px solid ${Colors.Black};
    border-radius: ${BorderRadius.RadiusXXS};

    .ant-notification-notice-content {
      .ant-notification-notice-message {
        color: ${Colors.Black};
        margin-bottom: 0;
      }

      .ant-notification-notice-description {
        margin-right: 32px;
        color: ${Colors.Black};
        font-weight: ${FontWeights.Regular};
      }

      .ant-notification-notice-icon {
        line-height: 18px;
      }
    }

    .ant-notification-notice-close {
      .ant-notification-notice-close-x {
        fill: ${Colors.White};
      }
    }
  }
`;
export { DropDownGlobalStyle };
