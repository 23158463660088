import {
  TVisitationUI,
  TVisitationDB,
  ESortFilds,
  TVisitationDetailDB,
  TVisitationDetailUI,
} from 'data/types/Visitations.types';
import {
  TPageableDataWithContentUi,
  TTableFilters,
} from 'data/types/Table.types';
import { EVisitationUrls } from 'data/urls/visitation.url';
import {
  getVisitationListConverter,
  getVisitationsConvertor,
} from 'data/convertors/Visitation.convertors';
import { authSplitApi } from 'redux/helpers/slice.helpers';

export const visitationApi = authSplitApi('visitation', [
  'visitationsList',
  'visitation',
]).injectEndpoints({
  endpoints: build => ({
    getVisitationList: build.query<
      TPageableDataWithContentUi<TVisitationUI[]>,
      TTableFilters<{ [key: string]: string | string[] }, ESortFilds>
    >({
      query({ page, pageSize, sortField, sortDirection, filters }) {
        const validFilters = getVisitationListConverter.toDb(filters);

        return {
          url: `${EVisitationUrls.VISITATIONS}?page=${page}&size=${pageSize}`,
          method: 'GET',
          params: {
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...validFilters,
          },
        };
      },
      providesTags: ['visitationsList'],
      transformResponse: (
        data: TPageableDataWithContentUi<TVisitationDB[]>,
      ) => {
        return {
          ...data,
          content: getVisitationListConverter.fromDb(data.content),
        };
      },
    }),
    getVisitations: build.query<
      TVisitationDetailUI,
      { animalVisitationId: string }
    >({
      query({ animalVisitationId }) {
        return {
          url: `${EVisitationUrls.VISITATIONS}/${animalVisitationId}`,
          method: 'GET',
          params: {
            animalVisitationId,
          },
        };
      },
      transformResponse: (data: TVisitationDetailDB) =>
        getVisitationsConvertor.fromDb(data),
      providesTags: ['visitation'],
    }),

    approveVisitation: build.mutation<void, { animalVisitationId: string }>({
      query({ animalVisitationId }) {
        return {
          url: `${EVisitationUrls.VISITATIONS}/${EVisitationUrls.APPROVE}`,
          method: 'PATCH',
          body: { animalVisitationId },
        };
      },
      invalidatesTags: ['visitation', 'visitationsList'],
    }),
    rejectVisitation: build.mutation<
      void,
      { animalVisitationId: string; reason: string }
    >({
      query({ animalVisitationId, reason }) {
        return {
          url: `${EVisitationUrls.VISITATIONS}/${EVisitationUrls.REJECT}`,
          method: 'PATCH',
          body: { animalVisitationId, reason },
        };
      },
      invalidatesTags: ['visitation', 'visitationsList'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetVisitationListQuery,
  useGetVisitationsQuery,
  useApproveVisitationMutation,
  useRejectVisitationMutation,
} = visitationApi;
