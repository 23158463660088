export enum ETenantUrls {
  TENANT = 'tenant',
  TAGS = 'tags',
  KNOWN = 'known',
  SEARCH = 'search',
  HP_DOC_PAKET = 'hp-doc-packet',
  VERIFY = 'verify',
  NOTIFICATIONS = 'notifications',
  DISABLE = 'disable',
  ENABLE = 'enable',
  DETAILS = 'details',
}
