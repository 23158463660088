/* eslint-disable @typescript-eslint/no-explicit-any */

export type TOptionTypeUi = {
  id?: string;
  __isNew__?: boolean;
  value: string | any;
  label: string;
};

export type TYesNoOptionType = 'Yes' | 'No' | null;

export type TEditingYesNoFieldUI = {
  value: TYesNoOptionType;
  canEdit: boolean;
};

export type TEditingYesNoFieldDB = TEditingBooleanField;

export type TSelectValueUI = TOptionTypeUi | undefined;
export type TSelectValueDB = {
  predefinedId: string | null;
  value: string | null;
};

export type TOptionType = {
  id?: string;
  value?: string;
};

export type TVetRecordTypeUI = {
  predefined?: string;
  custom?: string;
};

export type TVetRecordType = {
  predefined: string;
  value: string;
};

export type TExpirationDateType = {
  expired?: boolean;
  expirable: boolean;
  date: string;
};

export enum EFileTypeUI {
  VIDEO = 'video',
  PHOTO = 'image',
  OTHER = 'other',
}

export type TFileUI = {
  url: string;
  name: string;
  type: EFileTypeUI;
};

export type TFileDataType = {
  createdBy: string;
  createdDate: string;
  documentFileId: string;
  file: {
    name: string;
    url: string;
  };
  esaFraudCheck?: string | null;
};

export type TFileDataTypeUI = {
  createdBy: string;
  createdDate: string;
  documentFileId: string;
  name: string;
  url: string;
  esaFraudCheck?: string | null;
};

export type TErrorField = {
  name: string[];
  errors: string[];
  warnings: string[];
};

export type TValidateFieldsError = {
  errorFields: TErrorField[];
  outOfDate: boolean;
};

export enum EValueEditingStates {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET = 'SET',
}

export enum EValueEditingStatesUi {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET = 'SET',
}

export enum EVetRecordTypeEnum {
  'RABIES' = 'Rabies',
}

export enum EVetRecordTypeEnumUi {
  'RABIES' = 'Rabies',
  'VACCINATION' = 'Vaccination',
}
export type TEditingField = {
  predefinedId: string | null;
  value: string | null;
  allowedOperations: EValueEditingStates[];
};

export type TEditingFieldUi = {
  id: string;
  value: string;
  canEdit: boolean;
  canRemove: boolean;
  canAdd: boolean;
};

export type TEditingBooleanField = {
  value: boolean;
  allowedOperations: EValueEditingStates[];
};

export type TEditingBooleanFieldUi = {
  value: boolean;
  canEdit: boolean;
};

export enum EGenderUi {
  FEMALE = 'Female',
  MALE = 'Male',
}

export enum EGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum EReproductive {
  SPAYED = 'Spayed',
  NEUTERED = 'Neutered',
}

export enum EFailTypeUI {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
export enum EFailType {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
export enum EVerificationStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}
export enum EVerificationStatusUi {
  NOT_VERIFIED = 'Not verified',
  VERIFIED = 'Verified',
}

export enum EApplicationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
  RETRACTED = 'RETRACTED',
}

export enum EApplicationStatusUi {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  ARCHIVED = 'Archived',
  RETRACTED = 'Retracted',
}

export type TFailUI = { field: string; reason: string };
export type TFail = { [key: string]: string };

export enum ETextMessage {
  DEFAULT_ERROR_MESSAGE = 'Something went wrong',
  DEFAULT_SUCCESS_MESSAGE = 'Successfully done',
  DEFAULT_WARNING_TITLE = 'Missing information',
  MISSING_DOCUMENTS = 'Missing documents',
  VACCINATION_NO_EXPIRE = 'Documentation is from a licensed veterinarian exempting my animal from a rabies vaccination due to the animals age/health condition.',
  VACCINATION_OTHER_NO_EXPIRE = 'Vaccination has no expiration.',
}

export type TPropertyManagementInfoUI = {
  propertyManagementId: string | null;
  propertyManagementName: string | null;
  propertyManagementLogo: string | null;
  firstLetters: string;
};

export type TPropertyManagementInfoDB = {
  propertyManagementId: string | null;
  propertyManagementName: string | null;
  propertyManagementLogo: string | null;
};

export type TPropertyInfoDB = {
  propertyId: string | null;
  propertyName: string | null;
};
export type TPropertyInfoUI = {
  propertyId: string | null;
  propertyName: string | null;
};

export enum EAnimalPetChargeStatusUI {
  NOT_APPLICABLE = 'Not applicable',
  UNSPECIFIED = 'Unspecified',
  BEING_CHARGED = 'Being charged',
  NOT_BEING_CHARGED = 'Not being charged',
  GRACE_PERIOD = 'Grace period',
  SHOULD_NOT_BE_CHARGE = `Shouldn't be charged`,
}

export enum EAnimalPetChargeStatusDB {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  UNSPECIFIED = 'UNSPECIFIED',
  BEING_CHARGED = 'BEING_CHARGED',
  NOT_BEING_CHARGED = 'NOT_BEING_CHARGED',
  GRACE_PERIOD = 'GRACE_PERIOD',
  SHOULD_NOT_BE_CHARGE = `SHOULD_NOT`,
}

export type TStatusChangeInfoDB = {
  by?: string;
  date?: string;
  reason?: string;
  dueDate?: string;
  statusChangerOrgName?: string;
  statusChangerRole?: string;
  moveOutDate?: string;
};
export type TStatusChangeInfoUI = {
  by?: string;
  date?: string;
  reason?: string;
  dueDate?: string;
  statusChangerOrgName?: string;
  statusChangerRole?: string;
  moveOutDate?: string;
};
export type TStatusInfoWithSourceUI = TStatusChangeInfoUI & {
  exportDate?: string;
  name?: string;
  url?: string;
  moveOutDate?: string;
};
export type TStatusInfoWithSourceDB = TStatusChangeInfoDB & {
  exportDate?: string;
  name?: string;
  url?: string;
  moveOutDate?: string;
};
