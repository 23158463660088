import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TAppSliceState = {
  isFilterOpen: boolean;
  hasSelectedFilterItem: boolean;
  isUploadLoading: boolean;
};

const initialState: TAppSliceState = {
  isFilterOpen: false,
  isUploadLoading: false,
  hasSelectedFilterItem: false,
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setIsUploadLoading(state, action: PayloadAction<boolean>) {
      state.isUploadLoading = action.payload;
    },
    toggleFilterModal(state) {
      state.isFilterOpen = !state.isFilterOpen;
    },
    toggleHasSelectedFilterItem(
      state,
      action: PayloadAction<{ hasSelectedFilter: boolean }>,
    ) {
      state.hasSelectedFilterItem = action.payload.hasSelectedFilter;
    },
  },
});

export default appSlice;
