import styled from 'styled-components';
import {
  Layout as UIKitLayout,
  Sider,
  Header,
} from '@landlord-tech/ui-kit-opp/dist/components/Layout';

import {
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
} from 'core/CssVariables';
import { Menu } from '@landlord-tech/ui-kit-opp/dist/components/Menu';
import { TSider } from './Layout.types';

export const SLayoutWrapper = styled(UIKitLayout)`
  overflow: hidden;
  background-color: ${props => props.theme.backgroundColor};
  height: 100vh;
`;

export const SSider = styled<TSider>(Sider)`
  &&& {
    background-color: ${Colors.White};
    width: 230px !important;
    min-width: 230px !important;
    flex-shrink: 0;
    .imageContainer {
      margin: 16px 0px 28px 16px;
    }
    ${({ isSmallScreen }) =>
      isSmallScreen
        ? {
            position: 'absolute',
            top: 0,
            left: 0,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            height: '100%',
            zIndex: 100,
          }
        : {}};
    ${({ isCollapsed }) => (isCollapsed ? { display: 'none' } : {})};
  }
`;

export const SMenu = styled(Menu)`
  &&& {
    &.ant-menu-inline .ant-menu-item::after {
      border-right: none;
      margin-left: 16px;
      margin-bottom: 20px;
      background-color: ${Colors.Black};
      color: ${Colors.White};
    }

    .ant-menu-item {
      padding-left: 12px;
      padding-right: 30px;
      margin-bottom: 20px;
      background-color: ${Colors.White};
      height: fit-content;
      span {
        font-family: ${FontFamilies.FontSecondary};
        font-size: ${FontSizes.FontMD}px;
        font-weight: ${FontWeights.Medium};
      }
      &.ant-menu-item-active {
        color: ${Colors.Black};
      }
    }
    .ant-menu-item-selected {
      border-left: 4px solid ${Colors.PrimaryColor};
      span {
        display: block;
        width: 100%;
        background-color: black;
        color: white;
        padding: 10px 10px 10px 24px;
        border-radius: 11px;
      }
    }
  }
`;

export const SHeader = styled(Header)`
  &&& {
    background-color: ${Colors.PrimaryColor};
    padding: 0px 20px !important;
  }
`;
