import { TConvertor } from '../types/Converter.types';

import {
  EAnimalTagDB,
  EAnimalTagUI,
  ETenantTagDB,
  ETenantTagUI,
} from '../types/Tags.types';

export const tenantTagConverter: TConvertor<ETenantTagUI, ETenantTagDB> = {
  fromDb: data => {
    const statuses: {
      [key in ETenantTagDB]: ETenantTagUI;
    } = {
      [ETenantTagDB.KNOWN]: ETenantTagUI.KNOWN,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in ETenantTagUI]: ETenantTagDB;
    } = {
      [ETenantTagUI.KNOWN]: ETenantTagDB.KNOWN,
    };
    return statuses[data];
  },
};
export const tagConverter: TConvertor<EAnimalTagUI, EAnimalTagDB> = {
  fromDb: data => {
    const statuses: {
      [key in EAnimalTagDB]: EAnimalTagUI;
    } = {
      [EAnimalTagDB.KNOWN]: EAnimalTagUI.KNOWN,
      [EAnimalTagDB.PNC]: EAnimalTagUI.PNC,
      [EAnimalTagDB.DISCOVERED]: EAnimalTagUI.DISCOVERED,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in EAnimalTagUI]: EAnimalTagDB;
    } = {
      [EAnimalTagUI.KNOWN]: EAnimalTagDB.KNOWN,
      [EAnimalTagUI.PNC]: EAnimalTagDB.PNC,
      [EAnimalTagUI.DISCOVERED]: EAnimalTagDB.DISCOVERED,
    };
    return statuses[data];
  },
};
