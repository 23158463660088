import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Screens } from 'core/CssVariables';
import {
  Content,
  Layout as UIKitLayout,
} from '@landlord-tech/ui-kit-opp/dist/components/Layout';
import { SLayoutWrapper } from './Layout.styles';
import Sider from './components/Sider';
import Header from './components/Header';

const Layout = () => {
  const media = useMediaQuery({
    query: `(max-width: ${Screens.ScreensLG})`,
  });
  const [isCollapsed, setIsCollapsed] = useState(media);

  useEffect(() => {
    setIsCollapsed(media);
  }, [media]);

  return (
    <SLayoutWrapper>
      <Sider
        onSiderClose={() => setIsCollapsed(true)}
        isSmallScreen={media}
        isCollapsed={isCollapsed}
      />
      <UIKitLayout>
        <Header
          onSiderOpen={() => setIsCollapsed(false)}
          isCollapsed={isCollapsed}
        />
        <Content>
          <Outlet />
        </Content>
      </UIKitLayout>
    </SLayoutWrapper>
  );
};

export default Layout;
